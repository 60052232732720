<template>
    <v-app class="">
        <v-container class="">
            <v-dialog max-width="">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn width="0" v-bind="activatorProps" color="black" text="" variant="">
                        <svg-icon size="25" type="mdi" v-if="!add" :path="path_dec"></svg-icon>
                        <svg-icon size="25" type="mdi" v-if="add" :path="path_inc"></svg-icon>

                    </v-btn>
                </template>

                <template v-slot:default="{ isActive }">
                    <v-toolbar height="24">
                        <v-toolbar-items>

                            <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
                        </v-toolbar-items></v-toolbar>
                    <v-card title="Composition">
                        <section v-if="editMode">
                            <label><small>modifier une composition existante</small></label>
                            <v-form>

                                <v-row>
                                    <v-col cols="12" md="2">
                                        <label>Nom athlete</label>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>Poste</label>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>volumme d'entainement hebdo</label>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>volumme d'entainement spécialité</label>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>expériences dans la spécialité</label>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <label>définir comme entraîneur</label>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="2">
                                        {{ this.$data.current_athlete.athleteName }} {{
                                            this.$data.current_athlete.athleteSurname }}
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field name="poste" v-model="this.$data.current_composition.position"
                                            label="poste" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field name="Entr" v-model="this.$data.current_composition.vol_train"
                                            label="volumme d'entainement hebdo" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field name="Entr_spe" v-model="this.$data.current_composition.vol_spe"
                                            label="volumme d'entainement spécialité" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field name="exp_spe" v-model="this.$data.current_composition.exp_spe"
                                            label="expériences dans la spécialité" />
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-checkbox name="isTrainer" v-model="this.$data.current_composition.isTrainer"
                                            label="définir comme entraîneur" />
                                    </v-col>
                                </v-row>


                            </v-form>
                        </section>
                        <section v-else>
                            <label><small>créer une nouvelle composition</small></label>
                            <v-form>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field name="filter_athlete" v-model="search_athl"
                                            label="filtre Athlete" />
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field name="filter_team" v-model="search_team" label="Filtre Equipe" />
                                    </v-col>
                                    <v-col cols="12" md="4">

                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-select name="athlete" v-model="selected_athlete" :items="getAthleteList"
                                            label="Athlete" multiple chips persistent-hint />
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select name="equipe" v-model="selected_team" :items="getTeamList"
                                            label="Equipe" />
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select name="saison" v-model="selected_season" :items="getSeasons"
                                            label="Saison" />
                                    </v-col>
                                </v-row>

                                <template v-for="index in selected_athlete.length" :key="index">
                                    <v-row v-if="index == -1">
                                        <v-col cols="12" md="2">
                                            <label>Nom athlete</label>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <label>Poste</label>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <label>volumme d'entainement hebdo</label>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <label>volumme d'entainement spécialité</label>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <label>expériences dans la spécialité</label>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <label>définir comme entraîneur</label>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="index < selected_athlete.length">
                                        <v-col cols="12" md="2">
                                            {{ this.getAthleteInfos(selected_athlete[index]).athleteName }} {{
                                                this.getAthleteInfos(selected_athlete[index]).athleteSurname }}
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field name="poste" v-model="selected_athletes_poste[index]"
                                                label="poste" />
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field name="Entr" v-model="selected_athletes_vol_train[index]"
                                                label="volumme d'entainement hebdo" />
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field name="Entr_spe" v-model="selected_athletes_vol_spe[index]"
                                                label="volumme d'entainement spécialité" />
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field name="exp_spe" v-model="selected_athletes_exp[index]"
                                                label="expériences dans la spécialité" />
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-checkbox name="isTrainer" v-model="selected_athletes_istrainer[index]"
                                                label="définir comme entraîneur" />
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-form>
                        </section>
                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn v-if="editMode" text="modifier" @click="isActive.value = false; update()"></v-btn>
                            <v-btn v-else text="ajouter" @click="isActive.value = false; submit()"></v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>
//
// Component cycle are build on 
//
//
import SvgIcon from '@jamescoyle/vue-icon';
//import { mdiAccountInjury } from '@mdi/js';
//import { mapActions, mapGetters } from 'vuex';
import { mdiHumanCapacityIncrease } from '@mdi/js';
import { mdiHumanCapacityDecrease } from '@mdi/js';
export default {
    name: 'modale-composition',
    components: {
        SvgIcon
    },
    props: {
        editMode: Boolean,
        add: Boolean,
        idComposition: Number,
    },

    data() {
        return {
            path_inc: mdiHumanCapacityIncrease,
            path_dec: mdiHumanCapacityDecrease,
            current_composition: undefined, // edition d'une composition existante
            current_athlete: undefined,// edition d'un athlete existant
            selected_athlete: { "title": "", "value": "" },
            selected_athletes_poste: [],
            selected_athletes_vol_train: [],
            selected_athletes_vol_spe: [],
            selected_athletes_exp: [],
            selected_athletes_istrainer: [],
            selected_season: new Date().getFullYear(),
            selected_team: { "title": "", "value": "" },
            search_athl: "",
            search_team: "",
        };
    },
    async created() {


    },
    async mounted() {
        if (this.$props.idComposition) {
            // recupérer les informationa  pour éditions
            // composition 
            this.$data.current_composition = await this.$store.getters.getComposition(this.$props.idComposition);
            if (this.$data.current_composition != undefined) {
                // set if is trainer or not
                this.$data.current_composition.isTrainer = await this.$store.getters.isTrainer(this.$props.idComposition);
                // get referenced athlete 
                this.$data.current_athlete = await this.$store.getters.getAthlete(this.$data.current_composition.composition_idAthlete);
            }



        }

    },
    async updated() {
        this.$store.dispatch("getAllAthletes");
        this.$store.dispatch("getAllTeams");
    },
    computed: {
        getAthleteList() {
            let l = this.$store.getters.athletes;
            let result = [];
            l.forEach(element => {
                if (element.athleteSurname.includes(this.$data.search_athl) || element.athleteName.includes(this.$data.search_athl)) {
                    let tmp = { "title": element.athleteName + " " + element.athleteSurname, "value": element.idAthlete }
                    result.push(tmp);
                }


            })
            return result;
        },
        getTeamList() {
            let l = this.$store.getters.teams;
            let result = [];
            l.forEach(element => {
                if (element.teamName.includes(this.$data.search_team) || element.category.includes(this.$data.search_team)) {
                    let tmp = { "title": element.teamName + " " + element.category, "value": element.idTeam }
                    result.push(tmp);

                }
            })
            return result;
        },
        getSeasons() {
            return [...Array(100).keys()].map(i => i + 2000);
        }


    },

    methods: {
        async submit() {
            var compositions = []
            let idTeam = this.$data.selected_team;
            let season = new Date(this.$data.selected_season, 9, 1);// 1er septembre this.$data.selected_season;
            let seasonEnd = new Date(this.$data.selected_season + 1, 9, 1);// 1er septembre this.$data.selected_season;
            let index = 0;
            for (var element of this.$data.selected_athlete) {
                if (typeof (element) == "number") {


                    var tmp = {};
                    tmp.seasonStart = season;
                    tmp.seasonEnd = seasonEnd;
                    tmp.composition_idTeam = idTeam;
                    tmp.composition_idAthlete = element;
                    tmp.position = this.$data.selected_athletes_poste[index];
                    tmp.vol_train = this.$data.selected_athletes_vol_train[index];
                    tmp.vol_spe = this.$data.selected_athletes_vol_spe[index];
                    tmp.exp_spe = this.$data.selected_athletes_exp[index];
                    tmp.isTrainer = this.$data.selected_athletes_istrainer[index];
                    compositions.push(tmp);

                }
                index++;
            }
            this.$store.dispatch("createCompositions", compositions);
            this.$data.selected_athlete = { "title": "", "value": "" };
            this.$data.selected_athletes_poste = [];
            this.$data.selected_athletes_vol_train = [];
            this.$data.selected_athletes_vol_spe = [];
            this.$data.selected_athletes_exp = [];
            this.$data.selected_athletes_istrainer = [];
            this.$data.selected_season = new Date().getFullYear();
            this.$data.selected_team = { "title": "", "value": "" };
            this.$data.search_athl = "";
            this.$data.search_team = "";
        },
        async update() {
            this.$store.dispatch("updateComposition", this.$data.current_composition);
            // this.$data.current_composition= undefined; // edition d'une composition existante
            // this.$data.current_athlete= undefined;// edition d'un athlete existant
        },

        getAthleteInfos(idAthlete) {
            let result = this.$store.getters.athletes.find(element => element.idAthlete == idAthlete);
            return result;

        },
    }

};
</script>