import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import RegisterView from '../views/RegisterView';
import LoginView from '../views/LoginView';
import DashBoardView from '@/views/DashBoardView.vue';
import DashboardAdminView from '@/views/DashboardAdminView.vue';
import DashboardAthleteView from '@/views/DashboardAthleteView.vue';
import DashboardEntrainView from '@/views/DashboardEntrainView.vue';
import Athlete_details from '@/views/Athlete_details.vue';
import ConfirmView from '@/views/ConfirmView.vue';
import store from '@/store';
function filterAdminEnter(){
  return store.getters.isAdmin;
}
function filterTrainerEnter(){
  return store.getters.isEntrain;
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/register',
    name:'register',
    component: RegisterView
  },
  {
    path:'/login',
    name:'Login',
    component:LoginView
  },
  {
    path:'/dashboard',
    name:'Dashboard',
    component:DashBoardView
  },
  {
    path:'/admin',
    name:'Dashboard-admin',
    component:DashboardAdminView,
    beforeEnter:[filterAdminEnter],
  },
  {
    path:'/trainer',
    name:'Dashboard-train',
    component:DashboardEntrainView,
    beforeEnter:[filterTrainerEnter],
  },
  {
    path:'/trainee',
    name:'Dashboard-athlete',
    component:DashboardAthleteView
  },
  {
    path:'/suivi',
    name:'Athlete-suivi',
    component:Athlete_details
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path : '/confirm',
    name : 'confirm',
    component : ConfirmView
  }
];

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),// change to production server ?
  history: createWebHashHistory(),
  routes
});

export default router;
