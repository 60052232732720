<template>
    <div> <!-- partie titre et toolbox -->
      <section>
            <v-dialog max-width="auto" max-height="auto" scrollable>
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn  v-if="!editMode" v-bind="activatorProps" color="gray" text=""> <svg-icon type="mdi"
                    :path="path_add"></svg-icon></v-btn>
                <v-btn  v-if="editMode" v-bind="activatorProps" color="gray" text=""> <svg-icon type="mdi"
                    :path="path_edit"></svg-icon></v-btn>
              
              </template>

              
              <template v-slot:default="{ isActive }">
                <v-form class="" @submit.prevent="submit">
                  <v-toolbar height="24">
                        <v-toolbar-items>

                            <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
                        </v-toolbar-items></v-toolbar>
                  <v-card title="Modifier les informations athlète">
                    
                    <v-row dense>
                      <v-col cols="12" md="4" sm="6">
                        <v-text-field label="nom" name="name" v-model="new_athlete.athleteName" />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field label="prenom" name="surename" v-model="new_athlete.athleteSurname" />
                      </v-col>
                    </v-row>
                    <v-row dense>
  
                      <v-col cols="12" md="4">
                        <v-select name="main" v-model="new_athlete.lat_hand"
                          :items="[{ title: 'main droite', value: 1 }, { title: 'main gauche', value: 0 },{ title: 'les deux', value: 2 }]" label="" />
                      </v-col>
  
                      <v-col cols="12" md="4">
                        <v-select name="valeur main" v-model="new_athlete.lat_hand" :items="[0, 1,2]" label="valeur"
                          readonly />
                      </v-col>
                    </v-row>
                    <v-row dense>
  
                      <v-col cols="12" md="4">
                        <v-select name="pied" v-model="new_athlete.lat_foot"
                          :items="[{ title: 'pied droit', value: 1 }, { title: 'pied gauche', value: 0 },{ title: 'les deux', value: 2 }]" label="" />
                      </v-col>
  
                      <v-col cols="12" md="4">
                        <v-select name="valeur pied" v-model="new_athlete.lat_foot" :items="[0, 1,2]" label="valeur"
                          readonly />
                      </v-col>
                    </v-row>
                    <v-row dense>
  
                      <v-col cols="12" md="4">
                        <v-select label="genre" name="genre" v-model="new_athlete.sex" :items="['M', 'F']" />
                      </v-col>
                    </v-row>
                    <v-row> <v-col cols="6" md="4">
  
                        <v-date-picker id="date_athlete" label="date" title="Date de naissance"
                          :max-date="Date.parse('2010-01-01')" name="date" width="400" color="primary"
                          v-model="new_date" />
  
                      </v-col>
                    </v-row>
                    <v-btn v-if="!editMode" type="submit" class="btn btn-primary" @click="isActive.value = false">créer</v-btn>
                    <v-btn v-if="editMode" type="submit" class="btn btn-primary" @click="isActive.value = false">mettre à jour</v-btn>
                  </v-card>
                </v-form>
              </template>
            </v-dialog>
      </section>
    </div>
  </template>
  <script>
  //  import athletes from '@/store/modules/athletes';
  import { defineComponent } from 'vue';
  import { mapGetters, mapActions } from 'vuex';
  import '@/css/forms.css';
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiAccountPlusOutline } from '@mdi/js';
  import { mdiHumanEdit } from '@mdi/js';

  export default defineComponent({
    name: 'Dashboard-cep',
    components: {
      SvgIcon
    },
    props:{
      editMode:Boolean
    },
    data() {
      return {
        form: {
  
        },
     

        new_date: new Date(),
        path_edit: mdiHumanEdit,
        path_add: mdiAccountPlusOutline,
        
        
  
      };
    },
    created: function () {

    },
    updated:function(){
      
    },
    computed: {
      ...mapGetters({ new_athlete: 'new_athlete' }),
    
    },
    methods: {

      ...mapActions(['addAthlete']),
      ...mapActions(['updateAthlete']),
      async submit() {
        //this.redirect();
        let date_selected = this.new_date.toISOString();
        this.new_athlete.birthdate = date_selected;
        if(this.$props.editMode){
          await this.updateAthlete(this.new_athlete);
        }
        else{
          await this.addAthlete();
        }
        await this.$store.dispatch("getProfileAthlete");
        
      },
      
    
      async update() {
        this.redirect();
        let date_selected = this.selected_date.toISOString();
        this.selected.birthdate = date_selected;
        await this.updateAthlete(this.selected);
      },
    
    },
  });
  </script>