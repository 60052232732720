<template>
    <div>
        <section>
            <v-card>

                <div class="navigation-eval">

                    <v-card-action>
                        <v-btn icon="mdi-chevron-left" variant="plain" @click="prev"></v-btn>
                    </v-card-action>
                    <v-card-action>
                        <v-btn :variant="isSelected(0) ? 'outlined' : 'text'" icon="mdi-record"
                            @click="toggleWin(0)"></v-btn>
                    </v-card-action>
                    <v-card-action>
                        <v-btn :variant="isSelected(1) ? 'outlined' : 'text'" icon="mdi-record"
                            @click="toggleWin(1)"></v-btn>
                    </v-card-action>
                    <v-card-action>
                        <v-btn :variant="isSelected(2) ? 'outlined' : 'text'" icon="mdi-record"
                            @click="toggleWin(2)"></v-btn>
                    </v-card-action>
                    <v-card-action>
                        <v-btn icon="mdi-chevron-right" variant="plain" @click="next"></v-btn>
                    </v-card-action>

                </div>
            </v-card>
            <v-window v-model="window">
                <v-window-item key="0">
                    <section>
                        <v-toolbar class="custom-toolbox-sof" dense>
                            <v-btn-toggle v-model="toggle_multiple" color="primary" dense group multiple>
                                <v-btn :value="1" text class="button-toolbox">
                                    <EquipeForm :edit-mode="false" />
                                </v-btn>
                            </v-btn-toggle>
                            <div class="mx-4"></div>
                        </v-toolbar>
                        <!-- Liste des équipes  data table-->


                        <v-card title="équipes :" flat>

                            <template v-slot:text>
                                <v-text-field v-model="search" label="Filtrer" prepend-inner-icon="mdi-magnify"
                                    variant="outlined" hide-details single-line></v-text-field>
                            </template>
                            <v-data-table id="data-table-equipe" fixedHeader :headers="headers" :items="this.equipes"
                                :search="search" :items-per-page="50" multi-sort>
                                <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ item }"
                                    :key="header.key">
                                    <slot :name="`item.${header.key}`" :value="item[header.key]">
                                        <v-span v-if="header.key == 'idTeam'" height="5px" class="d-inline-flex">
                                            <EquipeForm :edit-mode="true" :idEquipe="item[header.key]" />
                                            <v-spacer></v-spacer>
                                            <v-btn height="25px"
                                                @click="this.popUpDelConfirmationTeam(item[header.key])">

                                                <svg-icon type="mdi" width="20px" color="#EE5555"
                                                    :path="path"></svg-icon>
                                            </v-btn>
                                        </v-span>
                                        <v-span v-else>
                                            {{ item[header.key] }}
                                        </v-span>
                                    </slot>
                                </template>
                            </v-data-table>
                        </v-card>
                    </section>
                </v-window-item>
                <v-window-item key="1">
                    <!-- Liste composition existantes ie (equipe,season) -->

                    <section>
                        <v-card>
                            <v-toolbar class="custom-toolbox-sof" dense>

                                <div class="mx-4"></div>
                                <v-btn-toggle v-model="toggle_multiple" color="primary" dense group multiple>
                                    <v-btn :value="2" text class="button-toolbox">
                                        <!-- ajout d'un athletes pour une  composition -->
                                        <modaleComposition :edit-mode="false" :add="true" />
                                    </v-btn>

                                </v-btn-toggle>
                            </v-toolbar>
                        </v-card>

                        <v-card title="Compositions des équipes :" flat>

                            <v-table id="filter-tab-admin">
                                <v-row width="33%">
                                    <v-col><v-text-field v-model="search_compo" label="Filtrer"
                                            prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
                                    </v-col>


                                </v-row>
                                <v-row width="33%">
                                    <v-col><v-text-field v-model="search_compoteam" label="Filtre equipe"
                                            prepend-inner-icon="mdi-magnify" hide-details></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="search_compotAthl" label="Filtre athlete"
                                            prepend-inner-icon="mdi-magnify" hide-details></v-text-field>

                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="search_compopost" label="Filtre poste "
                                            prepend-inner-icon="mdi-magnify" hide-details></v-text-field>

                                    </v-col>
                                    <v-col>
                                        <v-text-field v-model="season_start" label="Filtre saison "
                                            prepend-inner-icon="mdi-magnify" hide-details></v-text-field>

                                    </v-col>
                                </v-row>
                            </v-table>
                            <v-data-table id="data-table-compo" fixedHeader :headers="headers_compo"
                                :items="this.compositions" :searchCols="search_cols" :search="search_compo"
                                :items-per-page="50" multi-sort
                                >
                                <template v-for="h in headers_compo" v-slot:[`item.${h.key}`]="{ item }" :key="h.key">
                                    <slot :name="`item.${h.key}`" :value="item[h.key]">
                                        <v-span v-if="h.key == 'idComposition'" height="5px">
                                            <v-btn height="25px" @click="this.popUpDelConfirmationCompo(item[h.key])">
                                                <svg-icon width="20px" color="#EE5555" type="mdi"
                                                    :path="path"></svg-icon>
                                            </v-btn>
                                            <modaleComposition :edit-mode="true" :add="false" :idComposition="item[h.key]" />
                                        </v-span>
                                        <v-span v-if="h.key != 'idComposition' && h.key!='isTrainer'">

                                            {{ item[h.key] }}
                                        </v-span>
                                        <v-checkbox v-if="h.key =='isTrainer'" :disabled="true" :model-value="item[h.key]"></v-checkbox>
                                    </slot>
                                </template>
                            </v-data-table>
                        </v-card>

                    </section>
                </v-window-item>
                <v-window-item key="2">
                    <section>
                        <v-card title="Liste des entraineurs  :" flat>
                            <!-- Liste des entraîneurs -->
                            <v-data-table id="data-table-trainer" fixedHeader :headers="headers_trainer"
                                :items="this.trainers">
                                <template v-for="he in headers_trainer" v-slot:[`item.${he.key}`]="{ item }"
                                    :key="he.key">
                                    <slot :name="`item.${he.key}`" :value="item[he.key]">
                                        <v-span v-if="he.key == 'idTrainer'" height="5px">
                                            <v-btn height="25px"
                                                @click="this.popUpDelConfirmationTrainer(item[he.key], item[headers_trainer[1].key], item[headers_trainer[2].key])">
                                                <svg-icon width="20px" color="#EE5555" type="mdi"
                                                    :path="path"></svg-icon>
                                            </v-btn>
                                        </v-span>
                                        <v-span v-if="he.key == 'athleteName'">
                                            {{ item[he.key] }}
                                        </v-span>
                                        <v-span v-if="he.key == 'athleteSurname'">
                                            {{ item[he.key] }}
                                        </v-span>
                                        <v-span v-if="he.key == 'infos'">
                                            <v-chip v-for="elem in item[he.key]" :key="elem.key">
                                                {{ elem }}
                                            </v-chip>

                                        </v-span>
                                     

                                    </slot>
                                </template>
                            </v-data-table>
                        </v-card>

                    </section>
                </v-window-item>
            </v-window>
        </section>
    </div>

</template>
<script>
import { defineComponent, ref } from 'vue';
import EquipeForm from '@/components/EquipeForm.vue';
import modaleComposition from '@/components/modaleComposition.vue'
// import { mapGetters } from 'vuex';
// import { mapGetters, mapActions } from 'vuex';
// import DataTable from 'datatables.net-vue3';
// import DataTablesCore from 'datatables.net';
import '@/css/forms.css';
import SvgIcon from '@jamescoyle/vue-icon';
// import { mdiHumanEdit } from '@mdi/js';
// import { mdiAccountPlusOutline } from '@mdi/js';
// import { mdiDeleteForever } from '@mdi/js';
// import { mdiHeartPulse } from '@mdi/js';
import { mdiMinusCircleOutline } from '@mdi/js';

// DataTable.use(DataTablesCore);


export default defineComponent({
    
    name: "dashboard-admin",
    components: {
        EquipeForm,
        modaleComposition,
        SvgIcon,
    },

    data() {
        return {
            window: 0,
            path: mdiMinusCircleOutline,
            search: '',
            search_compo: '',
            search_compoteam: '',
            search_compotAthl: '',
            search_compopost: '',
            season_start: '2024',
            headers: ref([
                { title: "id", key: "idTeam", width: "10px", align: "center" },
                { title: "Nom de l'équipe", key: 'teamName', width: "10px", align: "center" },
                { title: "informations", key: 'teamInformations', width: "10px", align: "center" },
                { title: "club", key: "club", width: "10px", align: "center" },
                { title: "sport", key: "sport", width: "10px", align: "center" },
                { title: "category", key: "category", width: "10px", align: "center" },
            ]),
            headers_compo: ref([
                {
                    title: "id", key: "idComposition", width: "10px", align: "center",

                },
                {
                    title: "season", key: "seasonStart", width: "10px", align: "center",
                    filter: (value) => {
                        if (!this.$data.season_start) return true;
                        return value.startsWith(this.$data.season_start);
                    }

                },
                {
                    title: "Nom de l'équipe", key: 'composition_idTeam', width: "10px", align: "center",
                    filter: (value) => {
                        if (!this.$data.search_compoteam) return true;
                        return value.trim().includes(this.$data.search_compoteam);
                    }

                },
                {
                    title: "Nom de l'athlete", key: 'composition_idAthlete', width: "10px", align: "center",
                    filter: (value) => {
                        if (!this.$data.search_compotAthl) return true;
                        return value.trim().includes(this.$data.search_compotAthl);
                    }
                },
                {
                    title: "poste", key: "position", width: "10px", align: "center",
                    filter: (value) => {
                        if (!this.$data.search_compopost) return true;
                        return value.trim().includes(this.$data.search_compopost)
                    }
                },
                { title: "volume entrainement", key: "vol_spe", width: "10px", align: "center" },
                { title: "volume total entrainement", key: "vol_train", width: "10px", align: "center" },
                { title: "Entraineur", key: "isTrainer", width: "10px", align: "center" },
            ]),
            search_cols: [null, { search: this.season_start }, { search: this.search_compoteam }, { search: this.search_compotAthl }, { search: this.search_compopost }, null, null],
            headers_trainer: ref([
                { title: "id", key: "idTrainer", width: "10px", align: "center" },
                { title: "Nom ", key: 'athleteName', width: "10px", align: "center" },
                { title: "Prénom ", key: 'athleteSurname', width: "10px", align: "center" },
                { title: "Equipes", key: 'infos', width: "100px", align: "center" },
            ]),

        }
    },

    mounted: function () {
        this.$store.dispatch("getAllAthletes");
        this.$store.dispatch("getAllTeams");
        this.$store.dispatch("getAllCompositions");
        this.$store.dispatch("getAllTrainers");

    },
    computed: {
        equipes() {
            return this.$store.getters.teams;
        },
        compositions() {
            let compositions = JSON.parse(JSON.stringify(this.$store.getters.compositions));
            if (compositions != undefined && compositions.length != 0) {
                for (const index in compositions) {
                    let element = compositions[index];
                    let team = this.$store.getters.getTeam(element.composition_idTeam);
                    let athlete = this.$store.getters.getAthlete(element.composition_idAthlete);
                    let season = new Date(element.seasonStart).getFullYear();
                    element.seasonStart = String(season);
                    if (team != undefined) {
                        element.composition_idTeam = team.teamName;
                    }
                    if (athlete != undefined)
                        element.composition_idAthlete = athlete.athleteName + "  " + athlete.athleteSurname;
                    element.isTrainer=this.$store.getters.isTrainer(element.idComposition);
                }

                return compositions;
            }
            else {
                return [];
            }
        },
        trainers() {
            let trainers = JSON.parse(JSON.stringify(this.$store.getters.trainers_list));
            let athletes = JSON.parse(JSON.stringify(this.$store.getters.athletes));
            let trainers_infos = []
            if (trainers != undefined && trainers.length > 0) {
                for (var elem of trainers) {

                    let compositions = this.$store.getters.getCompositionByiDTrainer(elem.trainer_composition); // trainer _composition <> idTrainer !!:)
                    // on cherche à avoir un ensemble unique des compositions liées à l'entraîneur

                    let compositions_map = compositions?.map(item => [new Date(item.seasonStart).getFullYear(), item.composition_idTeam]);
                    let uniqueCompoKey = []
                    let uniqueCompoSet = []
                    if (compositions_map) {


                        for (const element of compositions_map) {
                            let key = String(element[0]).concat("_", String(element[1]));
                            if (!uniqueCompoKey.includes(key)) {
                                uniqueCompoKey.push(key);
                                uniqueCompoSet.push(element);
                            }
                        }
                        let distincTuple = Array.from(uniqueCompoSet); // eventuellement si unique Composet n'est pas un tableau
                        for (var dt of distincTuple) {
                            let team = this.$store.getters.getTeam(dt[1]);
                            dt.push(team); // rajoute au dictionnaire en fin de tableau 

                        }
                        let infos = distincTuple.map(elem =>"saison "+ elem[0] + " : " + elem[2].teamName + " , " + elem[2].category);
                        console.log(infos);
                        let athlete = athletes.find(ath => ath.idAthlete==elem.trainer_idAthlete);
                        if(athlete.infos == undefined){
                            // athlete.infos=infos
                            // athlete.infos=Object.assign([],infos);
                            athlete.infos = infos;
                            athlete.idTrainer = elem.idTrainer;
                            trainers_infos.push(athlete);
                        }
                        else{
                            athlete.infos.push(...infos);
                            console.log("in else")
                        }
                        
                    }
                }
                return trainers_infos;
            }
            else {
                return []
            }


        }


    },
    methods: {
        getDataComposition(){
            this.$store.dispatch("getAllCompositions");
            this.$store.dispatch("getAllTrainers");
        },
        getTeamName(id) {
            let team = this.$store.getters.getTeam(id);
            if (team != undefined) {
                return team.teamName;
            }
            else {
                return "N/A";
            }
        },
        popUpDelConfirmationTrainer(id, trainer, infos) {
            //
            //let item=this.$store.getters.getTrainerById(id);
            let respon = confirm("Vous supprimez ce lien entraînement :" + trainer + " " + infos);
            if (respon) {
                // find relative composition fist
                this.$store.dispatch("removeRelativeTrainerComp", id);
                // delete trainer 
                this.$store.dispatch("removeTrainer", id);

            }

            this.getDataComposition();

        },
        popUpDelConfirmationCompo(id) {
            let item = this.$store.getters.compositions.find(elem => elem.idComposition == id);
            let respon = confirm("Vous supprimez cette composition :" + item.composition_idAthlete + " " + item.composition_idTeam + " " + item.seasonStart);
            if (respon) {
                // optionaly remove the trainer if the linked trainer id athlete is the same as the composition trainer 
                this.$store.dispatch("removeRelativeTrainerifSame", id);

                // remove the composition
                this.$store.dispatch("removeComposition", id);
                //
            }

        },
        popUpDelConfirmationTeam(id) {
            let item = this.equipes.find(elem => elem.idTeam == id);
            let respon = confirm("vous supprimez cette équipe " + item.teamName + " " + item.teamInformations + " " + item.category);
            if (respon) this.$store.dispatch("removeTeam", id);
        },
        toggleWin(id) {
            if (this.$data.window != 1 && id == 1) {
                this.$data.window = 1;
            }
            if (this.$data.window != 0 && id == 0) {
                this.$data.window = 0;
            }
            if (this.$data.window != 2 && id == 2) {
                this.$data.window = 2;
            }
        },
        next() {
            if (this.$data.window < 2) {
                this.$data.window++;
            }
        },
        prev() {
            if (this.$data.window > 0) {
                this.$data.window--;
            }
        },
        isSelected(id) {
            if (id == this.$data.window) return true;
        },
    }
});


</script>