// import axios from 'axios';
import * as client from './client/index';
// import store from '@/store';
const key_sel_athlete="selAthlete";

const state = {
  athletes: [],
  new_athlete:{},
  selected_athlete:{birthdate:""},
  selected_date:"",
};

const getters = {
  getAthletes: state => state.athletes,
  selected: state => state.selected_athlete,
  selected_date: state => state.selected_date,
  new_athlete:state =>state.new_athlete,
  key_sel_athl: () =>key_sel_athlete,
};

const actions = {
  async addAthlete({dispatch}) {
    var token=this.getters.tokenUser;
    if (token == null){
      token=localStorage.getItem("token");
    }
    if(token){
      const data={athleteName:this.getters.new_athlete.athleteName,
      athleteSurname:this.getters.new_athlete.athleteSurname,
      sex:this.getters.new_athlete.sex,
      lat_hand:this.getters.new_athlete.lat_hand,
      lat_foot:this.getters.new_athlete.lat_foot,
      birthdate:this.getters.new_athlete.birthdate};
      const res=await client.putAthlete(token,data);
      await dispatch('getAthletes', [res?.lastrowid]);
    }
    else{
      console.error("cannot make a request with a proper token");
    }

    
  },
  async getAthletes({commit}, ids=[]) {
    var token=this.getters.tokenUser;
    if (token == null){
      token=localStorage.getItem("token");
    }
    const fetch = await client.readAthletesByIds(token,ids);
    commit('setAthletes',fetch.result);
  },
  async searchAthletes({commit},name){
    var token=this.getters.tokenUser;
    if (token == null){
      token=localStorage.getItem("token");
    }
    const fetch =await client.readAthleteByName(token,name);
    commit('setAthletes',fetch.result);
  },
  async selectAthlete({commit},id){
    for(const item  of this.getters.getAthletes){
      if(item.idAthlete == id){
        commit('setAthlete',item);

        break;
       }

    }
  },
  async updateAthlete({dispatch},athlete){
    var token=this.getters.tokenUser;
    if (token == null){
      token=localStorage.getItem("token");
    }
    await client.updateAthlete(token,athlete);
    dispatch('getAthletes');
  },
  async viewAthletes({commit},athlete) {
    
    commit('setAthlete', athlete);

  },
  // eslint-disable-next-line no-empty-pattern
  async deleteAthlete({dispatch}, data) {
    var token=this.getters.tokenUser;
    if (token == null){
      token=localStorage.getItem("token");
    }
    await client.deleteAthlete(token,data);
    dispatch('getAthletes',[]);
  },
  async cleanStatesAthletes({commit}){
    commit("cleanStatesAthletes");
  }

};

const mutations = {
  setAthletes(state,athletes_list) {
    state.athletes =athletes_list;

  },
  setAthlete(state,athlete) {
    state.selected_athlete =athlete;
    localStorage.setItem(key_sel_athlete,JSON.stringify(athlete))

  },
  setUserAthlete(state,athlete){
    state.new_athlete=athlete;

  },
  setSelectedDate(state,date) {
    state.selected_date =date;

  },
  cleanStatesAthletes(state){
    state.athletes= [];
    state.new_athlete={};
    state.selected_athlete={birthdate:""};
    state.selected_date="";
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};