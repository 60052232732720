//  fichier user.js 
//
//  description :
//    module store vuex pour regrouper certianes traitements pour un utilisateur 
//    (login,register ...)
//    Les méthodes de ce module sont appeleées au moment du login les states sont donc accessibles
//    pour toutes les sessions (admin, entraineur ou athlete)
//  
//  ressources : voire documentation vue3 et vuex  
// 
//import axios from 'axios';
import * as client from './client/index';
const key_expire = "expire_token";
const key_token = "token";
const key_user = "user";
const key_group_1 = "groupAthlete";
const key_group_2 = "groupEntrain";
const key_group_3 = "groupAdmin";
const keys = ["placeholder", key_group_3, key_group_2, key_group_1];
const state = {
  user: null,
  hasProfile: false,
  profile_athlet: null,
  group_athlete: false,
  group_entrain: false,
  group_admin: false,
  token: null,
  expire_date: null,
  isExpire: true,
  stateFormAthlete: ["empty"],
  poolingToken: undefined,
};

const getters = {
  isAuthenticated: state => !!state.expire,
  isAthlete: state => state.group_athlete,
  isEntrain: state => state.group_entrain,
  isAdmin: state => state.group_admin,
  stateUser: state => state.user,
  tokenUser: state => state.token,
  expire: state => state.expire_date,
  isTokenExpire: state => {

    return state.isExpire;


  },
  hasAthleteProfil: state => state.hasProfile,
  getProfile: state => state.profile_athlet,
  hasProfile: state => state.profile_athlet != null ? state.profile_athlet.idAthlete > 0 ? true : false : false,
  getStatesForm: state => state.stateFormAthlete,
  getStatesFormQuoti: state => {
    return state.stateFormAthlete.filter(elem => elem.isDaily === 1);
  },
  getStatesFormTest: state => {
    return state.stateFormAthlete.filter(elem => elem.isDaily === 0);
  },
  getStatesForChart: state => {
    //copy to avoid retrigger
    // first chart of the UI height and IMC
    var datas = JSON.parse(JSON.stringify(state.stateFormAthlete));
    // var index = 1;
    var series1 = [];
    var series2 = [];
    datas.forEach(element => {
      if (element != undefined && element != "empty" && element.weight) {
        let d = new Date(element.evalDate).toLocaleDateString('fr');
        let weight = Number.parseFloat(element.weight);
        let tmp1 = { x: String(d), y: weight };
        let imc = Math.round(weight / Math.pow((element.height + 0.01) / 100, 2));
        let tmp2 = { x: String(d), y: imc };

        // index++;
        series1.push(tmp1);
        series2.push(tmp2);
      }
    });
    var series = [{
      name: 'poids',
      type: 'line',
      data: series1,

    },
    {
      name: 'IMC',
      type: 'line',
      data: series2,

    }
    ]
    return series;
  },
  getStatesForMass: (state) => {
    var datas = JSON.parse(JSON.stringify(state.stateFormAthlete));
    // var index = 1;
    var series1 = [];
    var series2 = [];
    var series3 = [];
    var series4 = [];
    datas.forEach(element => {
      if (element != undefined && element != "empty" && element.isDaily == 0) {
        let d = new Date(element.evalDate).toLocaleDateString('fr');
        let weight = Number.parseFloat(element.weight);
        // we have ratio we compute amount 
        let fat = Math.round(Number.parseFloat(element.fatmass) * weight);
        let muscle = Math.round(Number.parseFloat(element.musclemass) * weight);
        let water = Math.round(Number.parseFloat(element.water) * weight);

        var tmp1 = { x: String(d), y: weight };
        var tmp2 = { x: String(d), y: fat };
        var tmp3 = { x: String(d), y: muscle };
        var tmp4 = { x: String(d), y: water };

        // index++;
        series1.push(tmp1);
        series2.push(tmp2);
        series3.push(tmp3);
        series4.push(tmp4);
      }
    });
    var series = [{
      name: 'poids',
      type: 'line',
      data: series1,

    },
    {
      name: 'graisse',
      type: 'line',
      data: series2,

    },

    {
      name: 'muscle',
      type: 'line',
      data: series3,

    },

    {
      name: 'eau',
      type: 'line',
      data: series4,

    }
    ]
    return series;
  },
  getStateForTableChart: state => {
    // dashboard athlete second windows ( view on history of daily states) 
    //headers key   fatigue sleep stress motivation mood RPE_WEEK
    var datas = JSON.parse(JSON.stringify(state.stateFormAthlete));
    var fat = [];
    var sle = [];
    var str = [];
    var mot = [];
    var moo = [];
    var rpe = [];
    datas.forEach(element => {
      if (element != undefined && element != "empty") {
        fat.push(element.fatigue);
        sle.push(element.sleep);
        str.push(element.stress);
        mot.push(element.motivation);
        moo.push(element.mood);
        rpe.push(element.RPE_WEEK);
      }
    });
    return [{ data: [{ x: "forme physique", y: fat }, { x: "sommeil", y: sle }, { x: "stress", y: str }, { x: "motivation", y: mot }, { x: "humeur", y: moo }, { x: "difficulte", y: rpe }] }];
  },
  getStateForMoodLineChart: (state) => {
    var datas = JSON.parse(JSON.stringify(state.stateFormAthlete));
    var fat = [];
    var sle = [];
    var str = [];
    var mot = [];
    var moo = [];
    var rpe = [];
    datas.forEach(element => {
      if (element != undefined && element != "empty" && element.isDaily === 1) {
        let d = new Date(element.evalDate).toLocaleDateString('fr');
        fat.push({ x: String(d), y: element.fatigue });
        sle.push({ x: String(d), y: element.sleep });
        str.push({ x: String(d), y: element.stress });
        mot.push({ x: String(d), y: element.motivation });
        moo.push({ x: String(d), y: element.mood });
        rpe.push({ x: String(d), y: element.RPE_WEEK });

      }
    });
    var series = [{
      name: 'fatigue',
      type: 'line',
      data: fat,

    },
    {
      name: 'sommeil',
      type: 'line',
      data: sle,

    },

    {
      name: 'stress',
      type: 'line',
      data: str,

    },

    {
      name: 'motivation',
      type: 'line',
      data: mot,

    },
    {
      name: 'Emotions',
      type: 'line',
      data: moo,

    },
    {
      name: 'difficulte',
      type: 'line',
      data: rpe,

    },
    ]
    return series;
  },
  poolingToken: state => state.poolingToken,
};

const actions = {
  async getProfileAthlete({ commit, dispatch }) {
    /**
     *  getProfileAthlete :
     *      retrieve athlete information for the current user
     */
    var token = this.getters.tokenUser;
    var exp = this.getters.expire;
    if (token == null) {
      token = localStorage.getItem("token");
    }
    if (exp == null) {
      exp = localStorage.getItem("expire_token");
    }
    var req = await client.readProfileAthlete(token, [])
    if (req.result && Object.keys(req.result).length > 0 && req.result.constructor === Object) {
      const datas = req.result
      commit('setProfile', datas);
      commit('setUserAthlete', datas);
      dispatch('getStatesOfFormForAthl');
    }


  },
  async verifyToken({ commit }) {
    /**
     *  verifyToken :
     *      check if current token is still valid
     *  periodically
     */

    var token = this.getters.tokenUser;
    var exp = this.getters.expire;
    if (token == null) {
      token = localStorage.getItem("token");
    }
    if (exp == null) {
      exp = localStorage.getItem("expire_token");
    }
    // if(token==null ||  exp==null || new Date(exp) < Date.now()){
    //   commit('setExpire',true);
    // }
    let result = await client.verifyToken(token, []);
    if (result.valide) {
      await commit('setExpire', false);
      await commit('setUser', result.token_full);
    }
    else {
      await commit('setExpire', true);
    }
    return result.valide;



  },
  async register({ dispatch }, form) {
    /**
     *  
     */
    await client.createUser(null, form);
    const UserForm = new FormData();
    UserForm.append('username', form.username);
    UserForm.append('password', form.password);
    await dispatch('logIn', UserForm);
  },
  async checkToken({ commit, state }) {
    if (state.poolingToken == undefined) {

      var callback = function () {
        var tdate = Date.parse(localStorage.getItem(key_expire));
        if (tdate < Date.now()) {
          return false;
        }
        else {
          return true;
        }
      };
      commit("setPoolingToken",callback);
    }
  },
  async logIn({ dispatch }, form) {
    // login from form
    //  
    //
    const user = form.get("username");
    const pass = form.get("password");
    var data = { formData: { username: user, password: pass } };
    const result = await client.loginForAccessToken(data);
    await dispatch('viewMe', result);
  },
  async getStatesOfFormForAthl({ commit }) {
    //
    //
    //
    const athlete = this.getters.getProfile;
    if (athlete == null)
      return false;
    var token = this.getters.tokenUser;
    if (token == null) {
      token = localStorage.getItem("token");
    }
    if (token) {
      const result = await client.readStateFormAthletes(token, athlete);
      if (result.result) {
        const datas = result.result
        await commit('setAthleteStateOfForm', datas)
      }
    }
  },

  async viewMe({ commit }, ressource) {
    const token_ctx = ressource.access_token;
    await commit('setUser', token_ctx);

  },
  // eslint-disable-next-line no-empty-pattern
  async deleteUser({ }) {
    // await axios.delete(`user/${id}`);
  },
  async logOut({ commit }) {
    const user = null;
    await commit('logout', user);
  }
};

const mutations = {
  setPoolingToken(state, callback) {
    state.poolingToken = setInterval(() => {
      if (callback) { state.expire=callback(); }
    }, 60*1000 );
  },
  setUser(state, token_ctx) {
    /**
     * setUser: 
     *  set data according to group and user  
     *    type group 1 -> admin
     *               2 -> entraineur
     *               3 -> athlete  
     * @param token_ctx: JWT
     * 
     */
    const arrayToken = token_ctx.split('.');
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    const expire_date = new Date(tokenPayload?.exp * 1000).toISOString();
    state.user = tokenPayload.user;
    //state.group=tokenPayload.group_infos;
    state.token = token_ctx;
    state.expire_date = expire_date;
    localStorage.setItem(key_token, token_ctx);
    localStorage.setItem(key_user, tokenPayload.user);
    localStorage.setItem(key_expire, expire_date);

    (tokenPayload.group_infos).forEach(group => {
      localStorage.setItem(keys[group.type], true);
    });
    if (eval(localStorage.getItem(key_group_3))) {
      state.group_admin = true;
    }
    if (eval(localStorage.getItem(key_group_2))) {
      state.group_entrain = true;
    }
    if (eval(localStorage.getItem(key_group_1))) {
      state.group_athlete = true;
    }
  },
  setAthleteStateOfForm(state, datas) {
    state.stateFormAthlete = datas;
  },
  setExpire(state, expire) {
    /**
     * state : stat struc
     * expire(bool)
     */
    state.isExpire = expire;
  },
  setProfile(state, value) {
    state.hasProfile = true;
    state.profile_athlet = value
  },
  logout(state, user) {
    state.user = user;
    state.hasProfile = false;
    state.profile_athlet = null;
    state.group_athlete = false;
    state.group_entrain = false;
    state.group_admin = false;
    state.token = null;
    state.expire_date = null;
    state.isExpire = true;
    state.stateFormAthlete = ["empty"];
    clearInterval(state.poolingToken);
    localStorage.clear();
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};