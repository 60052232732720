<template>
    <v-app class="">
        <v-container class="">
            <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                    <v-btn v-if="!editMode" width="10" height="10" v-bind="activatorProps" color="#696969" text="équipe" variant="" class="equipe-modale-btn"><svg-icon size="100"
                            type="mdi" :path="path"></svg-icon></v-btn>
                    <v-btn v-if="editMode"  v-bind="activatorProps" color="#696969" text="équipe" variant="" class=""><svg-icon 
                            type="mdi" :path="path_edit"></svg-icon></v-btn>
                    
                </template>

                <template v-slot:default="{ isActive }">
                    <v-toolbar height="24">
                        <v-toolbar-items>

                            <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
                        </v-toolbar-items></v-toolbar>
                    <v-card title="Gestion équipe">
                        <section >
                            
                            <section v-if="editMode">Modifier les informations d'une équipe
                                
                            <v-text-field class="w-50 ml-4" label="nom de l'équipe" id="teamName"
                                            v-model="this.$data.current_team.teamName" ></v-text-field>
                                <v-text-field class="w-50 ml-4" label="informations sur l'équipe" id="informations"
                                            v-model="this.$data.current_team.teamInformations" ></v-text-field>
                                <v-text-field class="w-50 ml-4" label="club" id="club"
                                            v-model="this.$data.current_team.club" ></v-text-field>
                                <v-text-field class="w-50 ml-4" label="sport" id="sport"
                                            v-model="this.$data.current_team.sport" ></v-text-field>
                                <v-text-field class="w-50 ml-4" label="category" id="category"
                                            v-model="this.$data.current_team.category" ></v-text-field>
                            
                            </section>
                            <section  v-else>
                                <label>Ajout d'une équipe</label>
                            <v-text-field class="w-50 ml-4" label="nom de l'équipe" id="teamName"
                                            v-model="new_team.teamName" ></v-text-field>
                                <v-text-field class="w-50 ml-4" label="informations sur l'équipe" id="informations"
                                            v-model="new_team.teamInformations" ></v-text-field>
                                <v-text-field class="w-50 ml-4" label="club" id="club"
                                            v-model="new_team.club" ></v-text-field>
                                <v-text-field class="w-50 ml-4" label="sport" id="sport"
                                            v-model="new_team.sport" ></v-text-field>
                                <v-text-field class="w-50 ml-4" label="category" id="category"
                                            v-model="new_team.category" ></v-text-field>
                            </section>
                        </section>
               
                            
                            
                                
                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn v-if="this.$props.editMode" text="modifer" @click="isActive.value = false;update() "></v-btn>
                            <v-btn v-else text="ajouter" @click="isActive.value = false;submit() "></v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>

import SvgIcon from '@jamescoyle/vue-icon';
//import { mdiAccountInjury } from '@mdi/js';
//import { mapActions, mapGetters } from 'vuex';
//import { mdiMedicationOutline } from '@mdi/js';
import { mdiSoccerField } from '@mdi/js';
import { mdiDatabaseEditOutline } from '@mdi/js';
export default {
    name: 'form-equipe',
    components: {
        SvgIcon,
    },
    props: {
        editMode: Boolean,
        idEquipe: Number,
    },
    data() {
        return {
            path: mdiSoccerField,
            path_edit:mdiDatabaseEditOutline,
            new_team:{},
            current_team:{}
        }
    },
    created:function (){
        if(this.$props.editMode) this.$data.current_team=this.$store.getters.getTeam(this.$props.idEquipe);
        
    },
    activated:function(){
         

    },
    computed: {
       
     
    },
    methods: {
       submit(){
         this.$store.dispatch("createNewTeam",this.$data.new_team)
       },
       update(){
        this.$store.dispatch("updateTeam",this.$data.current_team)
       }
     
        
    }

};
</script>