<template>
    <div>
        <section>
            <v-card>
                <v-toolbar class="custom-toolbox-sof" dense>


                    <v-btn-toggle v-model="toggle_multiple" color="primary" dense group multiple>
                        <v-btn :value="1" text>
                            <AthleteForms :edit-mode="this.$store.getters.hasAthleteProfil" />
                        </v-btn>
                    </v-btn-toggle>
                    <div class="mx-4"></div>
                    <v-btn-toggle v-model="toggle_exclusive" color="primary" dense group>
                        <v-btn :value="2" text>
                            <modaleEvaluationQuo :editMode="false" />
                        </v-btn>
                        <v-btn :value="3" text>
                            <modaleEvaluation :editMode="false" />
                        </v-btn>
                    </v-btn-toggle>

                </v-toolbar>

                <div class="navigation-eval">

                    <v-card-action>
                        <v-btn icon="mdi-chevron-left" variant="plain" @click="prev"></v-btn>
                    </v-card-action>
                    <v-card-action>
                        <v-btn :variant="isSelected(0) ? 'outlined' : 'text'" icon="mdi-record"
                            @click="toggleWin(0)"></v-btn>
                    </v-card-action>
                    <v-card-action>
                        <v-btn :variant="isSelected(1) ? 'outlined' : 'text'" icon="mdi-record"
                            @click="toggleWin(1)"></v-btn>
                    </v-card-action>
                    <v-card-action>
                        <v-btn :variant="isSelected(2) ? 'outlined' : 'text'" icon="mdi-record"
                            @click="toggleWin(2)"></v-btn>
                    </v-card-action>
                    <v-card-action>
                        <v-btn icon="mdi-chevron-right" variant="plain" @click="next"></v-btn>
                    </v-card-action>

                </div>
            </v-card>
            <VContainer>

                <!-- <div>

                    {{ this.updatedStates }}
                </div> -->

                <v-window v-model="window">
                    <v-window-item key="0">
                        <section>
                            <h3>Informations profile</h3>
                            <v-card id="infos">
                                <v-table theme="white" density="compact" class=" p-3 mb-1 ">

                                    <tbody>
                                        <tr>
                                            <td>nom</td>
                                            <td>{{ getName }}</td>
                                        </tr>
                                        <tr>
                                            <td>prénom</td>
                                            <td>{{ getSurname }}</td>
                                        </tr>
                                        <tr>
                                            <td>date de naissance</td>
                                            <td>{{ getBirthdate }}</td>
                                        </tr>
                                    </tbody>
                                </v-table>
                                <div class="d-flex flex-column">
                                    <apexchart id="weight-chart-sof" :series="this.computedDatas"
                                        :options="options_line">
                                    </apexchart>
                                    <apexchart id="weightRatio-chart-sof" :series="this.getStatesForMass"
                                        :options="options_line2">
                                    </apexchart>
                                    <apexchart id="daily-chart-sof" :series="this.getStateForMoodLineChart"
                                        :options="options_line3">
                                    </apexchart>
                                </div>
                            </v-card>
                        </section>
                    </v-window-item>

                    <v-window-item key="1">
                        <h3>Historique évaluations quotidiennes , blessures pathologies douleurs</h3>
                        <v-data-table-virtual v-if="hasProfile" id="etatsTable" class="mt-10 profileTable-sof strip"
                            :headers="headers" :items="this.getStatesForm" item-value="name">

                            <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ item }"
                                :key="header.key">
                                <slot :name="`item.${header.key}`" :value="item[header.key]">
                                    <!-- <span v-if="header.key == 'height'"> {{ item[header.key] }} cm</span>
                            <span v-if="header.key == 'weight'"> {{ item[header.key] }} kg</span> -->
                                    <span v-if="header.key == 'evalDate'">
                                        {{ new Date(item[header.key]).toLocaleDateString("fr") }} : {{
                                            item[headers[13].key] }}

                                        <!-- <div class="modaleInjury-sof d-inline-flex space-between"> -->

                                        <div width="20px" class="mr-6"
                                            v-if="headers[10].key == 'idInjury' && header.key == 'evalDate' && item[headers[10].key]">
                                            <modaleInjury width="20px" height="10" :edit-mode="False"
                                                :id-injury="item[headers[10].key]" />
                                        </div>

                                        <div width="20px"
                                            v-if="header.key == 'evalDate' && headers[11].key == 'idPathology' && item[headers[11].key]">
                                            <modalePathology :idPathology="item[headers[11].key]" />
                                        </div>
                                        <div width="20px"
                                            v-if="header.key == 'evalDate' && headers[12].key == 'idPain' && item[headers[12].key]">

                                            <modalePain :idPain="item[headers[12].key]" />

                                        </div>

                                        <!-- </div> -->
                                    </span>
                                    <div class="row-table-sof" v-if="header.key == 'evalDate'">
                                        <!-- //headers key   fatigue sleep stress motivation mood-->

                                        <apexchart
                                            :series="[{ data: [item[headers[4].key], item[headers[5].key], item[headers[6].key], item[headers[7].key], item[headers[8].key], item[headers[9].key]] }]"
                                            :options="options">
                                        </apexchart>
                                        <!-- TODO clean this code 
                                    <apexchart :series="this.getStateForTableChart" :options="options">
                                </apexchart> -->
                                    </div>

                                </slot>

                            </template>

                        </v-data-table-virtual>
                    </v-window-item>
                    <v-window-item key="2">
                        <h3>Historiques:</h3>
                        <section class="d-flex flex-column align-start mb-6 ">

                            <!-- <v-data-table-virtual class="table-histo-ms-sof" v-if="hasProfile" :headers="testHeaders"
                            :items="getStatesFormTest">
                            <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ item }"
                                :key="header.key">
                                <slot :name="`item.${header.key}`" :value="item[header.key]">

                                    <span v-if="header.key == 'evalDate'">
                                        {{ new Date(item[header.key]).toLocaleDateString("fr") }}
                                    </span>
                                    <span v-else>{{ item[header.key] }}</span>
                                </slot>
                            </template>
                        </v-data-table-virtual>-->
                            <div class="ma-2 pa-2 mb-auto">
                                <h4>Historique évaluations medico-sportives</h4>
                                <vue-excel-editor class="ma-2 pa-2 mb-auto" ref="medStates" v-model="getStatesFormTest">
                                    <button width="60px" @click="exportAsExcel"> Export Excel </button>
                                    <!-- <button @click="exportAsCsv"> Export CSV </button> -->
                                    <vue-excel-column field="height" label="Taille" type="number" width="50px" />
                                    <vue-excel-column field="weight" label="Poids" type="number" width="50px" />
                                    <vue-excel-column field="evalDate" label="date" type="date" width="60px" />
                                    <vue-excel-column field="comment" label="infos" type="string" width="150px" />
                                    <vue-excel-column field="fatmass" label="graisse" type="number" width="50px" />
                                    <vue-excel-column field="musclemass" label="muscle" type="number" width="50px" />
                                    <vue-excel-column field="water" label="hydrique" type="number" width="50px" />
                                    <vue-excel-column field="hr_rest" label="repos Cardio" type="number" width="50px" />
                                    <vue-excel-column field="hr_max" label="max Cardio" type="number" width="50px" />
                                    <vue-excel-column field="vma" label="VMA" type="number" width="50px" />
                                    <vue-excel-column field="vo2max" label="Vo2" type="number" width="50px" />
                                </vue-excel-editor>
                            </div>
                            <div class="ma-2 pa-2 mb-auto">
                                <h4>Historique évaluations quotidiennes</h4>
                                <vue-excel-editor class="ma-2 pa-2 mb-auto" ref="dailyStates" v-model="getStatesForm">
                                    <button width="100px" @click="exportAsExcel2"> Export Excel </button>
                                    <!-- <button @click="exportAsCsv2"> Export CSV </button> -->
                                    <vue-excel-column field="height" label="Taille" type="number" width="50px" />
                                    <vue-excel-column field="weight" label="Poids" type="number" width="50px" />
                                    <vue-excel-column field="evalDate" label="date" type="date" width="60px" />
                                    <vue-excel-column field="comment" label="infos" type="string" width="150px" />
                                    <vue-excel-column field="fatigue" label="fatigue" type="number" width="50px" />
                                    <vue-excel-column field="RPE_WEEK" label="difficulté" type="number" width="50px" />
                                    <vue-excel-column field="stress" label="stress" type="number" width="50px" />
                                    <vue-excel-column field="sleep" label="sommeil" type="number" width="50px" />
                                    <vue-excel-column field="motivation" label="motivation" type="number"
                                        width="50px" />
                                    <vue-excel-column field="mood" label="Émotions" type="number" width="50px" />

                                </vue-excel-editor>
                            </div>
                        </section>
                    </v-window-item>
                </v-window>
            </VContainer>
        </section>
    </div>

</template>
<script>

import { defineComponent, ref } from 'vue';
//import { mapActions } from 'vuex';
import { mapGetters, mapActions } from 'vuex';
// import DataTable from 'datatables.net-vue3';
// import DataTablesCore from 'datatables.net';
import '@/css/forms.css';
//import SvgIcon from '@jamescoyle/vue-icon';
// import { mdiHumanEdit } from '@mdi/js';
// import { mdiAccountPlusOutline } from '@mdi/js';
// import { mdiDeleteForever } from '@mdi/js';
//import { mdiHeartPulse } from '@mdi/js';
import AthleteForms from '@/components/AthleteForms.vue';
import modaleInjury from '@/components/modaleInjury.vue';
import modalePathology from '@/components/modalePathology.vue';
import modalePain from '@/components/modalePain.vue';
import modaleEvaluationQuo from '@/components/modaleEvaluationsQuo.vue';
import modaleEvaluation from '@/components/modaleEvaluation.vue';

// DataTable.use(DataTablesCore);
export default defineComponent({
    name: "dashboard-athlete",
    components: {
        //SvgIcon,
        AthleteForms,
        modaleInjury,
        modalePathology,
        modalePain,
        modaleEvaluation,
        modaleEvaluationQuo,

    },
    data() {
        return {
            // index:0,
            window: 0,

            options_line: {
                chart: {
                    id: "weight-line",
                    type: "line"
                },

                xaxis: {
                    type: "numeric",
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#008FFB",
                        },
                        labels: {
                            style: {
                                colors: "#008FFB"
                            }
                        },
                        title: {
                            text: "Poids",
                            style: {
                                color: "#008FFB"
                            }
                        }
                    },
                    {
                        opposite: true,
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: true,
                            color: "#00E396"
                        },
                        labels: {

                            style: {
                                colors: "#00E396"
                            }
                        },
                        title: {
                            text: "IMC",
                            style: {
                                color: "#00E396"
                            }
                        }
                    }
                ],
            },
            options_line2: {
                chart: {
                    id: "mass-line",
                    type: "line"
                },

                xaxis: {
                    type: "numeric",
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#008FFB",
                        },
                        labels: {
                            style: {
                                colors: "#008FFB"
                            }
                        },
                        title: {
                            text: "Poids",
                            style: {
                                color: "#008FFB"
                            }
                        }
                    },

                ],
            },
            options_line3: {
                chart: {
                    id: "mass-line",
                    type: "line"
                },

                xaxis: {
                    type: "numeric",
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#008FFB",
                        },
                        labels: {
                            style: {
                                colors: "#008FFB"
                            }
                        },
                        title: {
                            text: "valeur",
                            style: {
                                color: "#008FFB"
                            }
                        }
                    },

                ],
            },
            series: [],// this.computedDatas(),


            options: {
                series: [{
                    data: [{ x: "", y: "" }]
                }],
                chart: {
                    id: "table-eval",
                    type: 'bar'
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        borderRadiusApplication: 'end',
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: "category",
                    categories: ['Fatigue physique', 'Sommeil', 'Stress', 'Motivation', 'Humeur', 'Difficulte']
                },
                yaxis: {
                    min: 0,
                    max: 10,
                    stepSize: 1,
                }
            },

            // selected_date: new Date(),
            // new_date: new Date(),
            // path_edit: mdiHumanEdit,
            // path_add: mdiAccountPlusOutline,
            // path_delete:mdiDeleteForever ,

            headers: ref([{
                //title: 'Évaluations quotidiennes',
                // align: 'end',
                sortable: true,
                // key: 'name',
            },
            { title: "", key: "evalDate", width: "33%", align: "start" },
            { title: "", key: 'height', width: "0px", align: "start" },
            { title: "", key: 'weight', width: "0px", align: "start" },

            { title: "", key: "fatigue", width: "0px", align: "start" },
            { title: "", key: "sleep", width: "0px" },
            { title: "", key: "stress", width: "0px" },
            { text: "", key: "motivation", width: "0px" },
            { title: "", key: "mood", width: "0px" },
            { title: "", key: "RPE_WEEK", width: "0px" },
            { title: " ", key: "idInjury", width: "0px" },
            { title: " ", key: "idPathology", width: "0px" },
            { title: " ", key: "idPain", width: "0px" },
            { title: "", key: 'comment', width: "0px" },
            ]),
            testHeaders: ref([
                {

                    sortable: true,
                },
                { title: "Date", key: "evalDate", width: "15px", align: "start" },
                { title: "taille", key: 'height', width: "15px", align: "start" },
                { title: "poids", key: 'weight', width: "15px", align: "start" },
                { title: "infos", key: "comment", width: "15px" },
                { title: "masse graisseuse", key: "fatmass", width: "15px" },
                { title: "masse musculaire", key: "musclemass", width: "15px" },
                { title: "masse hydrique", key: "water", width: "15px" },
                { title: "Rythme cardique repos", key: "hr_rest", width: "15px" },
                { title: "Rythme cardiaque max", key: "hr_max", width: "15px" },
                { title: "VMA", key: "vma", width: "15px" },
                { title: "Vo2 max", key: "vo2max", width: "15px" },

            ]),
        }
    },
    created:async  function () {

        if (!this.hasProfile) {
            await this.$store.dispatch("getProfileAthlete");

        }
        if (this.hasProfile) {
            await this.$store.dispatch('getStatesOfFormForAthl');
        }





    },
    mounted: function () {


    },
    updated: function () {

    },
    computed: {

        ...mapGetters({ getStatesForm: 'getStatesFormQuoti' }),
        ...mapGetters({ getStatesFormTest: 'getStatesFormTest' }),
        ...mapGetters({ hasProfile: 'hasAthleteProfil' }),
        ...mapGetters({ profile: 'getProfile' }),
        ...mapGetters({ getStatesForChart: 'getStatesForChart' }),
        ...mapGetters({ getStatesForMass: 'getStatesForMass' }),
        ...mapGetters({ getStateForMoodLineChart: "getStateForMoodLineChart" }),
        //...mapGetters({ getStateForTableChart: 'getStateForTableChart' }),
        hasNotAthleteProfile(){
            return !!this.$store.getters.hasAthleteProfil;
           //return false;
        },
        updatedStates() {
            var datas = this.$store.getters.getStatesForm;
            return datas
        },
        computedDatas() {
            //this is has been  moved into the store user.js
            var datas = this.$store.getters.getStatesForm;
            // var index = 1;
            var series1 = [];
            var series2 = [];
            datas.forEach(element => {
                if (element != undefined && element.weight) {
                    let d = new Date(element.evalDate).toLocaleDateString('fr');
                    let weight = Number.parseFloat(element.weight);
                    let tmp1 = { x: String(d), y: weight };
                    let imc = Math.round(weight / Math.pow((element.height + 0.01) / 100, 2));
                    let tmp2 = { x: String(d), y: imc };

                    // index++;
                    series1.push(tmp1);
                    series2.push(tmp2);
                }
            });
            var series = [{
                name: 'poids',
                type: 'line',
                data: series1,

            },
            {
                name: 'IMC',
                type: 'line',
                data: series2,

            }
            ]
            return series;
        },
        getStateForTableChart() {
            //headers key   fatigue sleep stress motivation mood RPE_WEEK-->
            return this.$store.getters.getStateForTableChart;
        },

        getName() {

            if (this.$store.getters.getProfile) return this.$store.getters.getProfile.athleteName;
            return '';
        },
        getSurname() {
            if (this.$store.getters.getProfile) return this.$store.getters.getProfile.athleteSurname;
            return '';
        },
        getBirthdate() {
            if (this.$store.getters.getProfile) return this.$store.getters.getProfile.birthdate;
            return '';
        },


    },
    methods: {

        computedXAxis() {
            //unused 
            var datas = this.$store.getters.getStatesForm;
            var xaxis = Array.from({ length: datas.length + 1 }, (_, i) => i);
            // var xaxis=[1,2,3,4];
            // return xaxis;
            var index = 1;
            datas.forEach(element => {
                if (element != undefined && element != "empty") {
                    //let d = new Date(element.evaldDAte).toTimeString();
                    xaxis.push(index);
                    index++;
                }
            });
            return xaxis;
        },

        setDatasChart(item, headers) {
            var datas = [

            ];
            var sliced_h = headers.slice(3, 7);
            sliced_h.forEach(element => {
                var data = {
                    x: element.key,
                    y: item[element.key],
                };
                datas.push(data);
            });
            return datas;
        },
        ...mapActions(['setCurrentStateOfForm']),

        toggleWin(id) {
            if (this.$data.window != 1 && id == 1) {
                this.$data.window = 1;
            }
            if (this.$data.window != 0 && id == 0) {
                this.$data.window = 0;
            }
            if (this.$data.window != 2 && id == 2) {
                this.$data.window = 2;
            }
        },
        next() {
            if (this.$data.window < 2) {
                this.$data.window++;
            }
        },
        prev() {
            if (this.$data.window > 0) {
                this.$data.window--;
            }
        },
        isSelected(id) {
            if (id == this.$data.window) return true;
        },
        exportAsExcel() {
            const format = 'xlsx'
            const exportSelectedOnly = (this.$refs.medStates.selected.length == 0) ? false :true; 
            const filename = this.getName + "_" + this.getSurname + "_medic"
            this.$refs.medStates.exportTable(format, exportSelectedOnly, filename)
        },
        exportAsCsv() {
            const format = 'csv'
            const exportSelectedOnly = (this.$refs.medStates.selected.length == 0) ? false :true; 
            const filename = this.getName + "_" + this.getSurname + "_medic"
            this.$refs.medStates.exportTable(format, exportSelectedOnly, filename)
        },
        exportAsExcel2() {
            const format = 'xlsx'
            const exportSelectedOnly = (this.$refs.dailyStates.selected.length == 0) ? false :true; 
            const filename = this.getName + "_" + this.getSurname + "_quoti"
            this.$refs.dailyStates.exportTable(format, exportSelectedOnly, filename)
        },
        exportAsCsv2() {
            const format = 'csv'
            const exportSelectedOnly = (this.$refs.dailyStates.selected.length == 0) ? false :true; 
            const filename = this.getName + "_" + this.getSurname + "_quoti"
            this.$refs.dailyStates.exportTable(format, exportSelectedOnly, filename)
        }

    }


});


</script>