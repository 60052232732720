<template>
    <div>
        <section>
            <!-- affiche la liste des équipe filtre-->
            <v-card title="équipes :" flat>
                <v-data-table height="200px" id="trainer-table-equipe" fixedHeader :headers="team_header"
                    :items="this.getListTrainerTeams" :search="search" :items-per-page="10" multi-sort>
                    <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ item }" :key="header.key">
                        <slot :name="`item.${header.key}`" :value="item[header.key]">
                            <v-span v-if="header.key == 'idTeam'" height="5px" class="d-inline-flex">
                                <EquipeForm :edit-mode="false" :idEquipe="item[header.key]" />
                            </v-span>
                            <v-span v-else>
                                {{ item[header.key] }}
                            </v-span>
                        </slot>
                    </template>
                </v-data-table>
            </v-card>
            <!-- affiche la liste des joueurs par équipe avec option de sélection -->
            <v-card title="joueurs :" flat>
                <template v-slot:text>
                    <v-text-field v-model="search" label="Filtrer" prepend-inner-icon="mdi-magnify" variant="outlined"
                        hide-details single-line></v-text-field>
                </template>
                <v-data-table :select="true" height="200px" id="trainer-table-joueur" :ref="joueur_table" fixedHeader
                    :headers="player_header" :rows-props="index" :items="this.getListTrainerAthlete" :search="search"
                    :items-per-page="10" multi-sort @click:row="selectAthlete">

                    <template v-for="header in player_header" v-slot:[`item.${header.key}`]="{ item }"
                        :key="header.key">

                        <slot :name="`item.${header.key}`" :value="item[header.key]">
                            <v-span v-if="header.key.startsWith('lat')" height="5px">
                                <span v-if="item[header.key] == 0">Gaucher</span>
                                <span v-else>Droitier</span>
                            </v-span>
                            <v-span v-else>
                                <span v-if="header.key == 'idAthlete'">

                                    <v-checkbox class="trainer_athl_sel" multiple
                                        v-model="selected_athlete[item[header.key]]"
                                        @click="this.t_getAthleteById(item[header.key], selected_athlete[item[header.key]])"></v-checkbox>

                                </span>
                                <span v-else>
                                    {{ item[header.key] }}
                                </span>
                            </v-span>
                        </slot>
                    </template>
                </v-data-table>
            </v-card>
            <section v-if="isSelectedAthlete() && this.$store.getters.trainer_selected_athlete">


                <h3>Informations profile</h3>
                <v-card id="infos">
                    <v-table theme="white" density="compact" class=" p-3 mb-1 ">

                        <tbody>
                            <tr>
                                <td>nom</td>
                                <td>{{ this.$store.getters.trainer_selected_athlete?.athleteName }}</td>
                            </tr>
                            <tr>
                                <td>prénom</td>
                                <td>{{ this.$store.getters.trainer_selected_athlete?.athleteSurname }}</td>
                            </tr>
                            <tr>
                                <td>date de naissance</td>
                                <td>{{ this.$store.getters.trainer_selected_athlete?.birthdate }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                    <v-card>

                        <div class="navigation-eval">

                            <v-card-action>
                                <v-btn icon="mdi-chevron-left" variant="plain" @click="prev"></v-btn>
                            </v-card-action>
                            <v-card-action>
                                <v-btn :variant="isSelected(0) ? 'outlined' : 'text'" icon="mdi-record"
                                    @click="toggleWin(0)"></v-btn>
                            </v-card-action>
                            <v-card-action>
                                <v-btn :variant="isSelected(1) ? 'outlined' : 'text'" icon="mdi-record"
                                    @click="toggleWin(1)"></v-btn>
                            </v-card-action>
                            <v-card-action>
                                <v-btn :variant="isSelected(2) ? 'outlined' : 'text'" icon="mdi-record"
                                    @click="toggleWin(2)"></v-btn>
                            </v-card-action>
                            <v-card-action>
                                <v-btn :variant="isSelected(3) ? 'outlined' : 'text'" icon="mdi-record"
                                    @click="toggleWin(3)"></v-btn>
                            </v-card-action>
                            <v-card-action>
                                <v-btn icon="mdi-chevron-right" variant="plain" @click="next"></v-btn>
                            </v-card-action>

                        </div>
                    </v-card>
                    <v-window v-model="window">
                        <v-window-item key="0">
                            <!-- affiche les informations de santé sur un joueurs -->
                      
                                <!-- affiche évaluation médiale-->
                                <apexchart id="weight-chart-sof" :series="this.$store.getters.computedDatasTrainer"
                                    :options="options_line">
                                </apexchart>
                        </v-window-item>
                        <v-window-item key="1">
                            <apexchart id="weightRatio-chart-sof" :series="this.$store.getters.statesForMassTrainer"
                                    :options="options_line2">
                                </apexchart>
                        </v-window-item>
                        <v-window-item key="2">
                            <!-- affiche etat de forme autonome -->
                        
                                <apexchart id="daily-chart-sof"
                                    :series="this.$store.getters.stateForMoodLineChartTrainer" :options="options_line3">
                                </apexchart>
                        </v-window-item>


                    
                        <v-window-item key="3">
                            <!-- affiche liste pathologie-->
                            <v-card title="Pathologies :" flat>
                                <template v-slot:text>
                                    <v-text-field v-model="search" label="Filtrer" prepend-inner-icon="mdi-magnify"
                                        variant="outlined" hide-details single-line></v-text-field>
                                </template>
                                <v-data-table height="200px" fixedHeader :headers="pathologie_header"
                                    :items="this.$store.getters.local_state_patho" :search="search_patho"
                                    :items-per-page="10" multi-sort>

                                    <template v-for="header in pathologie_header"
                                        v-slot:[`item.${header.key}`]="{ item }" :key="header.key">

                                        <slot :name="`item.${header.key}`" :value="item[header.key]">

                                            {{ item[header.key] }}

                                        </slot>
                                    </template>
                                </v-data-table>
                            </v-card>
                            <!-- affiche liste blessures-->
                            <v-card title="Blessures :" flat>
                                <template v-slot:text>
                                    <v-text-field v-model="search_injury" label="Filtrer" prepend-inner-icon="mdi-magnify"
                                        variant="outlined" hide-details single-line></v-text-field>
                                </template>
                                <v-data-table height="200px" fixedHeader :headers="injury_header"
                                    :items="this.$store.getters.local_state_injury" :search="search_injury"
                                    :items-per-page="10" multi-sort>

                                    <template v-for="header in injury_header"
                                        v-slot:[`item.${header.key}`]="{ item }" :key="header.key">

                                        <slot :name="`item.${header.key}`" :value="item[header.key]">
                                            <v-span v-if="header.key == 'idInjury'">
                                                <modaleInjury :editMode="false" :idInjury="item[header.key]"/>
                                            </v-span>
                                            <v-span v-else>
                                                {{ item[header.key] }}
                                            </v-span>
                                            

                                        </slot>
                                    </template>
                                </v-data-table>
                            </v-card>
                            <!-- affiche liste douleurs -->
                            <v-card title="Douleurs :" flat>
                                <template v-slot:text>
                                    <v-text-field v-model="search_pain" label="Filtrer" prepend-inner-icon="mdi-magnify"
                                        variant="outlined" hide-details single-line></v-text-field>
                                </template>
                                <v-data-table height="200px" fixedHeader :headers="pain_header"
                                    :items="this.$store.getters.local_state_pain" :search="search_pain"
                                    :items-per-page="10" multi-sort>

                                    <template v-for="header in pain_header"
                                        v-slot:[`item.${header.key}`]="{ item }" :key="header.key">

                                        <slot :name="`item.${header.key}`" :value="item[header.key]">
                                            <v-span v-if="header.key == 'idPain'">
                                                <modalePain :editMode="false" :idPain="item[header.key]"/>
                                            </v-span>
                                            <v-span v-else>
                                                {{ item[header.key] }}
                                            </v-span>
                                            

                                        </slot>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-window-item>

                    </v-window>
                </v-card>

            </section>





        </section>
    </div>

</template>
<script>
//import { mdiPictureInPictureBottomRightOutline } from '@mdi/js';
import { defineComponent, ref } from 'vue';
import modaleInjury from '@/components/modaleInjury.vue';
// import modalePathology from '@/components/modalePathology.vue';
import modalePain from '@/components/modalePain.vue';
// import { mapGetters, mapActions } from 'vuex';
// import DataTable from 'datatables.net-vue3';
// import DataTablesCore from 'datatables.net';
// import '@/css/forms.css';
// import SvgIcon from '@jamescoyle/vue-icon';
// import { mdiHumanEdit } from '@mdi/js';
// import { mdiAccountPlusOutline } from '@mdi/js';
// import { mdiDeleteForever } from '@mdi/js';
// import { mdiHeartPulse } from '@mdi/js';
import '@/css/forms.css';
import { mdiMinusCircleOutline } from '@mdi/js';
// import SvgIcon from '@jamescoyle/vue-icon';
// DataTable.use(DataTablesCore);
export default defineComponent({
    name: "dashboard-trainer",

    components: {
        modaleInjury,
        // modalePathology,
        modalePain,
        // SvgIcon,

    },
    setup() {

    },
    data() {
        return {
            window: 0,
            path: mdiMinusCircleOutline,
            current_index: 0,
            search_patho:'',
            search_injury:'',
            options_line: {
                chart: {
                    id: "weight-line",
                    type: "line"
                },

                xaxis: {
                    type: "numeric",
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#008FFB",
                        },
                        labels: {
                            style: {
                                colors: "#008FFB"
                            }
                        },
                        title: {
                            text: "Poids",
                            style: {
                                color: "#008FFB"
                            }
                        }
                    },
                    {
                        opposite: true,
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: true,
                            color: "#00E396"
                        },
                        labels: {

                            style: {
                                colors: "#00E396"
                            }
                        },
                        title: {
                            text: "IMC",
                            style: {
                                color: "#00E396"
                            }
                        }
                    }
                ],
            },
            options_line2: {
                chart: {
                    id: "mass-line",
                    type: "line"
                },

                xaxis: {
                    type: "numeric",
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#008FFB",
                        },
                        labels: {
                            style: {
                                colors: "#008FFB"
                            }
                        },
                        title: {
                            text: "Poids",
                            style: {
                                color: "#008FFB"
                            }
                        }
                    },

                ],
            },
            options_line3: {
                chart: {
                    id: "mass-line",
                    type: "line"
                },

                xaxis: {
                    type: "numeric",
                },
                yaxis: [
                    {
                        axisTicks: {
                            show: true
                        },
                        axisBorder: {
                            show: true,
                            color: "#008FFB",
                        },
                        labels: {
                            style: {
                                colors: "#008FFB"
                            }
                        },
                        title: {
                            text: "valeur",
                            style: {
                                color: "#008FFB"
                            }
                        }
                    },

                ],
            },
            local_list: [],
            local_stateOfForm: [],
            local_athlete: {},
            selected_class: "selected_row",
            selected_athlete: [],
            // isSelectedAthlete:false,
            selected_row: null,
            select_table: {
                style: 'os',
                selector: "td:first-child"
            },
            search: "",
            team_header: ref([
                {

                    sortable: true,
                },
                { title: "équipe", key: "teamName", align: "center" },
                { title: "information", key: 'teamInformations', align: "center" },
                { title: "club", key: 'club', align: "center" },
                { title: "sport", key: "sport", align: "center" },
                { title: "category", key: "category", align: "center" },

            ]),
            player_header: ref([
                {

                    sortable: true,
                },
                { title: "", key: "idAthlete", width: 0 },
                { title: "nom", key: "athleteName", align: "center" },
                { title: "prenom", key: 'athleteSurname', align: "center" },
                { title: "date de naissance", key: 'birthdate', align: "center" },
                { title: "sexe", key: "sex", },
                { title: "Main ", key: "lat_hand", },
                { title: "Pied ", key: "lat_foot", },

            ]),
            injury_header:ref([
                {
                    sortables:true,
                },
                { title:"details" , key:"idInjury"},
                { title:"description" , key:"injury_comment"},
                { title:"date" , key:"evalDate"},
                { title:"liens" , key:"external_identifier"},
            ]),
            pain_header:ref([
                {
                    sortables:true,
                },
                { title:"details" , key:"idPain"},
                { title:"mesure" , key:"measure"},
                { title:"date" , key:"evalDate"},

            ]),
        }
    },
    created: function () {
        this.$store.dispatch("getCompoForTrainer");
        this.$store.dispatch("getTeamForTrainer");
        this.$store.dispatch("getAthleteForTrainer");
        
    },
    mounted: function () {


    },
    computed: {

        getListCompositions() {

            return this.$store.getters.trainer_compo;
        },
        getListTrainer() {
            return this.$store.getters.local_trainers;
        },
        getListTrainerTeams() {
            return this.$store.getters.trainer_teams;
        },
        getListTrainerAthlete() {
            return this.$store.getters.trainer_athlete;
        },



    },
    methods: {
        // getIndex() {

        //     // var r = this.$data.current_index;
        //     // this.$data.current_index = this.$data.current_index + 1;
        //     // return r;
        // },
        async t_getAthleteById(id, val) {
            for (var elm of this.$data.selected_athlete) {
                // set all other values at false
                if (this.$data.selected_athlete.indexOf(elm) != id && elm != undefined && elm[0] === true) {
                    for (let index in elm) {
                        elm[index] = false;
                    }

                }

            }
            //toggle the value for the id
            if (this.$data.selected_athlete[id] != undefined && this.$data.selected_athlete[id][0] != undefined) {
                // after the first click have proxy 
                val = !this.$data.selected_athlete[id][0];

            }
            else if (this.$data.selected_athlete[id] != undefined) {
                // this may not happen but ..
                val = !this.$data.selected_athlete[id];
            }
            else {
                // supposely the first click on this item 
                //
                val = true;
            }
            this.$data.selected_athlete[id] = val;
            //let id=this.$data.selected_athlete;
            if (val) {

                await this.$store.dispatch("trainer_setAthleteById", id);
                await this.$store.dispatch("getStateOfFormTrainerAthl");
       

            }
            // else {
            //     await this.$store.dispatch("reset_localStates");
            // }

        },
        async trainer_getStateForAthlete(id) {
            var athlete = this.$store.getters.trainer_getAthleteById(id);
            if (athlete?.idAthlete == this.$data.local_athlete.idAthlete) {
                return this.$data.local_stateOfForm;
            }
            else {
                this.$data.local_stateOfForm = await this.$store.dispatch("getStateOfFormTrainerAthl", id);
                return this.$data.local_stateOfForm;
            }
        },
        isSelectedAthlete() {
            for (var elm of this.$data.selected_athlete) {
                // console.log(elm);
                if ((elm === true) || (elm && elm[0])) {
                    console.log(elm[0]);
                    return true;
                }

            }
            return false;
        },
        selectAthlete(item, index) {
            // (item, index, internalItem)
            // var table=item.target.parentNode.parentNode.parentNode;
            // table.row.deselect();
            // this.$data.selected_athlete=table.row(':eq('+row.index+')',{ page : 'current'}).select();
            console.log(index);
            // console.log(internalItem.row);
            // var tr_items = item.target.parentNode.parentNode.getElementsByTagName("tr");
            // for (const elem of tr_items) {
            //     elem.classList.remove(this.$data.selected_class);
            // }
            // item.target.parentNode.classList.add(this.$data.selected_class);
            // this.$data.selected_row=row;
        },
        toggleWin(id) {
            if (this.$data.window != 1 && id == 1) {
                this.$data.window = 1;
            }
            if (this.$data.window != 0 && id == 0) {
                this.$data.window = 0;
            }
            if (this.$data.window != 2 && id == 2) {
                this.$data.window = 2;
            }
            if (this.$data.window != 3 && id == 3) {
                this.$data.window = 3;
            }
        },
        next() {
            if (this.$data.window < 3) {
                this.$data.window++;
            }
        },
        prev() {
            if (this.$data.window > 0) {
                this.$data.window--;
            }
        },
        isSelected(id) {
            if (id == this.$data.window) return true;
        },


    }

});


</script>