<template>
  <header>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="container">
        <a class="navbar-brand" href="/">Gestion Equipes CEP </a>
        <small> </small>
        <button @click="collapse()" class="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon">
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul v-if="!this.isExpire" class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Accueil</router-link>
            </li>
            <li class="nav-item">
              <router-link v-if="this.isAdmin" class="nav-link" to="/admin">Tableau de bord administration</router-link>
            </li>
            <li class="nav-item">
              <router-link v-if="this.isEntrain" class="nav-link" to="/trainer">Tableau de bord
                Entraînement</router-link>
            </li>
            <li class="nav-item">
              <router-link v-if="this.isAthlete" class="nav-link" to="/trainee">Tableau de bord athlète</router-link>
            </li>
            <!-- <li class="nav-item">
                <router-link class="nav-link" to="/profile">Mon Profile</router-link>
              </li> -->
            <li class="nav-item">
              <a class="nav-link" @click="logout">deconnexion</a>
            </li>
          </ul>
          <ul v-else class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Accueil</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/register">S'enregistrer</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/login">Connexion</router-link>
            </li>
          </ul>

        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { defineComponent,ref } from 'vue';
import { mapGetters } from 'vuex';



export default defineComponent({
  name: 'NavBar',
  data() {
    return {
      isCollapsed: false,
      poolToken:ref(this.$store.getters.poolToken),
      expire:ref(this.$store.getters.isTokenExpire),
    }
  },
  updated: function () {

    this.$store.dispatch("verifyToken");
    if (this.$store.getters.isTokenExpire) {
      this.$router.push("/login");
    }

  },
  watch:{
    expire(newState) {
      if(!newState){
        this.$store.dispath("logOut");
        this.$router.push("/login");
      }
    },

  },
  computed: {
    ...mapGetters({ isTokenExpire: "isTokenExpire" }),

    isAthlete() {
      return this.$store.getters.isAthlete;
    },
    isEntrain() {
      return this.$store.getters.isEntrain;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isExpire() {
      let value = this.$store.dispatch("verifyToken");
      if (!this.$store.getters.isTokenExpire) return this.$store.getters.isTokenExpire;
      else {
        return value;
      }



    },
  },
  methods: {
    collapse() {
      if (this.$data.isCollapsed) {
        document.getElementById("navbarCollapse").style.display = "none";
        this.$data.isCollapsed = false;
      }
      else {
        document.getElementById("navbarCollapse").style.display = "block";
        this.$data.isCollapsed = true;
      }
    },
    async logout() {
      await this.$store.dispatch('logOut');
      await this.$store.dispatch("cleanStatesSuivi");
      await this.$store.dispatch("cleanStatesEntraineur");
      await this.$store.dispatch("cleanStatesComposition");
      await this.$store.dispatch("cleanStatesAthletes");
      this.$router.push('/login');
    },



  },
});
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>