/*
// fichier : compositions. js 
//  
//  description : modules store vuex,  pour regroper les traitements sur les équipes, 
//                  ajout d'équipe ,
//                  ajout 'entraîneur,
//                  ajout d'athlete pour une saison (compositions)
//  Une composition est définine à partir de l'ajout d'un  entraîneur  et d'une équipe :
//              équipe -> ajouter entraieur avec saison <-> composition
//                          un entraineur est sélectionné depuis la liste user avec group 2
//                          on ajouter une entrée sur trainer avec l'iduser             
//              composition -> ajouter un athlete en copiant la composition
//
//                            
//  
//
//////////////////////////////////////////////////////////////////////////////////////*/
import * as client from './client/index';

const state = {
    list_team: [],
    list_trainers: [],
    list_compo: [],//all list of composition must be in a virtual table
    list_dist_compo: [], // distinct composition by season and trainer
    composition_list_for_season: [],
    list_athletes: [],

};

const getters = {
    trainers_list: (state) => state.list_trainers,
    isTrainer: (state) => (idComposition) => {
        let trainers = state.list_trainers;// list of all existent trainer at this moment
        let composition = state.list_compo.find(elem => elem.idComposition == idComposition); // the current compostion to be removed
        if (trainers != undefined && trainers != [] ) {
            // list of trainers array type
            trainers = state.list_trainers.filter(elem => elem.trainer_composition == composition.composition_trainers);
  
            for (const elm of trainers) {
                if (elm.trainer_idAthlete == composition.composition_idAthlete) return true;
            }
        }
        return false;
    },
    teams: (state) => state.list_team,
    getTeam: (state) => (id) => {
        return state.list_team.filter(team => team != undefined).find((team) => team.idTeam === id);
    },
    getTeamName: (state) => (id) => {
        if (id) {

            let item = state.list_team.filter(team => team != undefined).find((team) => team.idTeam === id);
            return item.teamName;
        }

    },
    getAthlete: (state) => (id) => {
        if (id) {

            return state.list_athletes.filter(athl => athl != undefined).find((athl) => athl.idAthlete === id);
            //return item.athleteName;
        }

    },
    getComposition: (state) => (id) => {
        if (id) {
            return state.list_compo.filter(elem => elem != undefined).find((elem) => elem.idComposition === id);
        }
    },
    getCompositionByiDTrainer: (state) => (id) => {
        if (id) {
            return state.list_compo.filter(elem => elem != undefined).filter(elem => elem.composition_trainers == id);
        }
    },


    compositions: (state) => state.list_compo,
    compositions_: (state) => {
        /*copy to avoid retrgierring by mutation*/
        let _list = JSON.parse(JSON.stringify(state.list_compo));
        if (_list != undefined && state.list_compo.length != 0) {
            _list.forEach(elem => {
                if (elem != undefined) this.getters.getTeamName(elem.composition_idTeam);
            })
            return _list;
        }
        return [];
    },
    athletes: (state) => state.list_athletes,
    getTrainerById: (state) => (id) => state.list_trainers.find(elem => elem.idtrainer == id),
};
const actions = {
    async getAllTeams({ commit }) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result = await client.readTeamsByIds(token, []);

            commit("setTeams", result.result);
        }
    },
    async getAllAthletes({ commit }) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result = await client.readAthletesByIds(token, [])

            commit("setAthletes", result.result)
        }
    },
    async getAllCompositions({ commit }) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result = await client.readCompositionByTeamIds(token, [])

            commit("setCompositons", result.result)
        }
    },
    async getAllTrainers({ commit }) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result = await client.readTrainerForAthlete(token, [])

            commit("setTrainers", result.result)
        }
    },
    async createNewTeam({ dispatch }, datas) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            await client.putTeam(token, datas)

            dispatch("getAllTeams")
        }
    },
    async updateTeam({ dispatch }, datas) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            await client.updateTeam(token, datas);

            dispatch("getAllTeams");
        }
    },

    async updateComposition({ dispatch }, composition) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var result =null;
            if (composition.isTrainer != undefined && composition.isTrainer) {
                // we may not have all the compositions that exists we want to check if someone is defined has a trainer
                // this should be the set we work on
                let result_compo = await client.readCompositionsTeamSeason(token, composition);
                let allCompositions = result_compo.result;
                // find one or several trainer on this set
                var trainers = await client.readCompositionTrainers(token, allCompositions);
                let athlete_result = await client.readAthletesByIds(token, [composition.composition_idAthlete]);
                composition.idUser = athlete_result.result[0].athlete_user; // inject the idUser un-noramlized with the composition 
                composition.trainer_idAthlete = athlete_result.result[0].idAthlete;// inject the idAthlete same way
                if (trainers.result != undefined && trainers.result != [] && trainers.result.length > 0) {
                    // we use any of those previous trainer with trainer_composition field
                    composition.composition_trainers=trainers.result[0].trainer_composition;
                    result = await client.createTrainerComposition(token, composition);

                }
                else{
                    //we should create a new trainer records 
                   
                    result = await client.createCompositionTrainer(token, composition);
                    let idComposition_trainer = result.result.idcomposition_trainer; // this is the ids we use for all the data
                    composition.composition_trainers=idComposition_trainer;
                }
               
            }
            else{
                // we should delete the entry in trainers list if any
                await dispatch("removeRelativeTrainerifSame",composition.idComposition);
                composition.composition_trainers=null;

            }
            await client.updateCompositon(token, composition); // link composition -> composition_trainer
            // we need to create a trainer records 
            dispatch("getAllTrainers");
            dispatch("getAllCompositions");
        }
    },
    async removeComposition({ dispatch }, idComposition) {
        let elem = this.getters.compositions.find(element => element.idComposition == idComposition)
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            await client.deleteComposition(token, elem)
            dispatch("getAllCompositions");
        }
    },
    async removeRelativeTrainerifSame({ dispatch }, idCompo) {
        /**
         * removeRelativeTrainerifSame :  
         *  remove a trainer if we find it in the trainer list matching the composition idAthelte against the trainer idAhtlete
         * 
         * 
         *  remove a trainer if while removing a composition we find the same athelte id in
         *  one of the relative  trainers
         *  we check from the composition -> composition_trainer <- trainer []
         * 
         *  The list trainer may have duplicated idAthlete
         *  this is safe because from a composition we find a unique athlete
         *  and a severals trainers 
         *  but if we have a duplicated  row for this particular trainer and athlete
         *  it does not matter as we have still the same number of composition with idAthlete and trainer with idAthlete
         *  and so we only remove the first occurence we find  
         */

        let result = false;
        let trainers = this.getters.trainers_list;// list of all existent trainer at this moment
        let composition = this.getters.compositions.find(elem => elem.idComposition == idCompo); // the current compostion to be removed
        if (trainers != undefined && trainers.lenghth > 0) {
            // list of trainers array type
            trainers = this.getters.trainers_list.filter(elem => elem.trainer_composition == composition.composition_trainers);
        }
        if (trainers && trainers.length > 0) {
            // we find trainer to remove but we dont know if the composition_athleteid is the same 


            var token = this.getters.tokenUser;

            if (token == null) {
                token = localStorage.getItem("token");
            }
            if (token && trainers) {

                for (const trainer of trainers) {
                    if (trainer.trainer_idAthlete == composition.composition_idAthlete) {
                        result = await client.deleteTrainerForAthlete(token, trainer.idTrainer);
                        break; // because there may be another composition with the same athlete id defined as trainer ...
                    }
                }
                // }
                // else {
                //     // should never be fired ...
                //     if (trainers.trainer_idAthlete == composition.composition_idAthlete) {
                //         result = await client.deleteTrainerForAthlete(token, trainers.idTrainer);

                //     }
                // }


            }
        }
        dispatch("getAllTrainers");
        dispatch("getAllCompositions");
        return result;


    },
    async removeRelativeTrainerComp({ dispatch }, id) {
        // remove the composition when removing the trainer from the trainer UI 
        //
        let trainer = undefined;
        if (this.getters.trainers_list != undefined) {
            trainer = this.getters.trainers_list.find(elem => elem.idTrainer == id);
        }
        if (trainer != undefined) {


            let compositions = this.getters.compositions.find(elem => elem.composition_idAthlete == trainer.trainer_idAthlete);
            var token = this.getters.tokenUser;
            if (token == null) {
                token = localStorage.getItem("token");
            }
            if (token) {
                let results = [];
                if (typeof (compositions) == Array) {


                    for (let comp of compositions) {
                        if (comp.composition_trainers != undefined && comp.composition_trainers == trainer.trainer_composition) {
                            let result = await client.deleteComposition(token, comp);
                            results.push(result);
                        }
                    }
                }
                else {
                    let result = await client.deleteComposition(token, compositions);
                    results.push(result);
                }
            }
        }
        dispatch("getAllCompositions");
        dispatch("getAllTrainers");
    },
    async removeTeam({ dispatch }, idTeam) {
        let elem = this.getters.teams.find(elem => elem.idTeam == idTeam);
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            await client.deleteTeam(token, elem)
            dispatch("getAllTeams");
        }
    },
    async removeTrainer({ dispatch }, idTrainer) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            await client.deleteTrainerForAthlete(token, idTrainer)
            dispatch("getAllTrainers");
        }
    },
    async createCompositions({ dispatch }, datas) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {



            let compositions = []
            let new_trainers = []
            //create compositions for all athletes in  datas
            for (var elem of datas) {
                if (typeof (elem.seasonStart) == 'object') {
                    elem.seasonStart = elem.seasonStart.toISOString();
                }
                if (typeof (elem.seasonEnd) == 'object') {
                    elem.seasonEnd = elem.seasonEnd.toISOString();
                }
                let compo_result = await client.createComposition(token, elem);
                let idCompo = compo_result.result.lastrowid;
                let compo = compo_result.result.last_inserted_params;
                compo.idComposition = idCompo;
                compositions.push(compo);
                if (elem.isTrainer) {
                    new_trainers.push(elem);
                }
            }

            // we may not have all the compositions that exists we want to check if someone is defined has a trainer
            // this should be the set we work on
            let result_compo = await client.readCompositionsTeamSeason(token, compositions[0]);
            let allCompositions = result_compo.result;
            // find one or several trainer on this set
            var trainers = await client.readCompositionTrainers(token, allCompositions);
            if (trainers.result != undefined && trainers.result != [] && trainers.result.length > 0) {
                // we use any of those previous trainer with trainer_composition field
                for (var el in compositions) {
                    el.composition_trainers = trainers[0].trainer_composition
                    //update the element async
                    client.updateCompositon(token, el);

                }


            }

            // we create eventually a trainer only if we have selected a trainer right on this processing
            if (new_trainers.length > 0) {
                //find user_id for the trainer ( why ... ? poor design °^°) user_id may be null (but dont expected to be)
                let athlete_result = await client.readAthletesByIds(token, [new_trainers[0].composition_idAthlete]);


                new_trainers[0].idUser = athlete_result.result[0].athlete_user; // inject the idUser un-noramlized with the composition 
                new_trainers[0].trainer_idAthlete = athlete_result.result[0].idAthlete;// inject the idAthlete same way
                var result = await client.createCompositionTrainer(token, new_trainers[0])

                let idComposition_trainer = result.result.idcomposition_trainer; // this is the ids we use for all the data
                for (let c in compositions) {
                    // update the local composition
                    compositions[c].composition_trainers = idComposition_trainer;
                    //update the element async
                    await client.updateCompositon(token, compositions[c]);

                }
                for (let idx in allCompositions) {
                    // update all composition also 
                    allCompositions[idx].composition_trainers = idComposition_trainer;
                    client.updateCompositon(token, allCompositions[idx]);
                }
            }



            dispatch("getAllCompositions");
            dispatch("getAllTrainers"); // we may have new trainers as well 

        }

    },
    async cleanStatesComposition({ commit }) {
        commit("cleanStatesComposition");
    },
};

const mutations = {

    setTeams(state, teams) {
        state.list_team = teams;
    },
    setCompositons(state, compo) {
        state.list_compo = compo;
    },
    setAthletes(state, athletes) {
        state.list_athletes = athletes;
    },
    setTrainers(state, trainers) {
        state.list_trainers = trainers;
    },
    cleanStatesComposition(state) {
        state.list_team = [];
        state.list_trainers = [];
        state.list_compo = [];//all list of composition must be in a virtual table
        state.list_dist_compo = []; // distinct composition by season and trainer
        state.composition_list_for_season = [];
        state.list_athletes = [];
    }

};

export default {
    state,
    getters,
    actions,
    mutations
};