<template>
    <section>
     
      <form @submit.prevent="submit">
        <div class="mb-3">
          <label for="username" class="form-label">Mail </label>
          <input type="text" name="username" v-model="user.mail" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="full_name" class="form-label">Confirm Mail:</label>
          <input type="text" name="full_name" v-model="user.confirm" class="form-control" />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password:</label>
          <input type="password" name="password" v-model="user.password" class="form-control" />
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
      <VSpacer></VSpacer>
      <v-snackbar v-model="snack"> {{ this.$data.message }} 
        <v-btn color="pink"  icon="mdi-close" text @click="snack = false"></v-btn>
      </v-snackbar>
    </section>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { mapActions } from 'vuex';
  
  export default defineComponent({
    name: 'Register-cep',
    data() {
      return {
        user: {
          mail: '',
          confirm: '',
          password: '',
         
        },
        snack:true,
        message:'faites une demande d\'enegistrement sur le site ',
      };
    },
    methods: {
      ...mapActions(['register']),
      async submit() {
        if(this.user.confirm===this.user.mail){
          
        try {
          await this.register(this.user);
          
        } catch (error) {
          console.error(error);
          // throw 'Unexpected error ';
          //this.$router.push('/login');
          
        }finally{
          this.$data.user.mail='';
          this.$data.user.confirm='';
          this.$data.user.password='';
          this.$data.message=' Vérifier votre messagerie pour confirmer votre inscription';
          this.$data.snack=true;
        }
        }
        else{
          alert("les mails doivent être identiques")
        }
        //this.$router.push('/login');
      },
    },
  });
  </script>
  