//
// fichier : entraineur.js
//
//  description : module store pour regrouper les traitements usr le ftableau de bord entraineur
//              
//          en particulier : 
//                  . liste des équipes pour le profil user donné
//                  . liste des athleltes par equipe
//                  . evaluation des athletes 
//
//
//
//
///////////////////////////////////////////////////////////////////////////////
import * as clt from "./client/index"

const state = {
    local_trainer: [],
    trainer_teams: [],
    trainer_athlete: [],
    trainer_athl_compo: [],
    current_athlete: null,
    local_stateOfFOrm: [],
};

const getters = {
    trainer_teams: (state) => state.trainer_teams,
    trainer_athlete: (state) => state.trainer_athlete,
    trainer_getAthleteById: (state) => (id) => {
        var athlete = state.trainer_athlete.find(elem => elem.idAthlete == id);
        if (athlete != undefined) {
            return athlete;
        }
        return null;
    },
    trainer_selected_athlete: (state) => state.current_athlete,
    trainer_compo: (state) => state.trainer_athl_compo,
    local_trainers: (state) => state.local_trainer,
    local_stateOfFOrm: (state) => state.local_stateOfFOrm,
    local_state_patho: (state) => {
        var datas = JSON.parse(JSON.stringify(state.local_stateOfFOrm));
        var pathologies=[];
        if(datas != undefined){
            for(var elm of datas){
                if(elm.idPathology != undefined){
                    let tmp={};
                    tmp.idPathology=elm.idPathology;
                    tmp.pathologyName=elm.pathologyName;
                    tmp.description=elm.description;
                    tmp.date=elm.evalDate;
                    tmp.comment=elm.comment;
                    pathologies.push(tmp);
                }
             
            }
        }
        return pathologies;
    },
    local_state_injury: (state) => {
        var datas = JSON.parse(JSON.stringify(state.local_stateOfFOrm));
        var injuries=[];
        if(datas != undefined){
            for(var elm of datas){
                if(elm.idInjury != undefined){
                    let tmp={};
                    tmp.idInjury=elm.idInjury;
                    tmp.injury_idLocalisation=elm.injury_idLocalisation;
                    tmp.injury_comment=elm.injury_comment;
                    tmp.evalDate=elm.evalDate;
                    tmp.external_identifier=elm.external_identifier;
                    injuries.push(tmp);
                }
             
            }
        }
        return injuries;
    },
    local_state_pain: (state) =>{
        var datas = JSON.parse(JSON.stringify(state.local_stateOfFOrm));
        var pains=[];
        if(datas != undefined){
            for(var elm of datas){
                if(elm.idPain != undefined){
                    let tmp={};
                    tmp.idPain=elm.idPain;
                    tmp.pain_idLocalisation=elm.injury_idLocalisation;
                    tmp.measure=elm.measure;
                    tmp.evalDate=elm.evalDate;
                    pains.push(tmp);
                }
             
            }
        }
        return pains;
    },
    computedDatasTrainer: (state) => {
        // var datas = await  this.$store.dispatch("getStateOfFormTrainerAthl", id);
        // this.$data.local_stateOfForm = datas;
        var datas = JSON.parse(JSON.stringify(state.local_stateOfFOrm));
        // var index = 1;
        var series1 = [];
        var series2 = [];
        if (datas) {


            for (const element of datas) {
                if (element != undefined && element != "empty" && element.weight) {
                    let d = new Date(element.evalDate).toLocaleDateString('fr');
                    let weight = Number.parseFloat(element.weight);
                    let tmp1 = { x: String(d), y: weight };
                    let imc = Math.round(weight / Math.pow((element.height + 0.01) / 100, 2));
                    let tmp2 = { x: String(d), y: imc };

                    // index++;
                    series1.push(tmp1);
                    series2.push(tmp2);
                }
            }
        }
        var series = [{
            name: 'poids',
            type: 'line',
            data: series1,

        },
        {
            name: 'IMC',
            type: 'line',
            data: series2,

        }
        ]

        return series;
    },
    statesForMassTrainer: (state) => {
        var series1 = [];
        var series2 = [];
        var series3 = [];
        var series4 = [];
        var datas = JSON.parse(JSON.stringify(state.local_stateOfFOrm));
        if (datas != undefined) {
            for (const element of datas) {
                if (element != undefined && element != "empty" && element.isDaily == 0) {
                    let d = new Date(element.evalDate).toLocaleDateString('fr');
                    let weight = Number.parseFloat(element.weight);
                    // we have ratio we compute amount 
                    let fat = Math.round(Number.parseFloat(element.fatmass) * weight);
                    let muscle = Math.round(Number.parseFloat(element.musclemass) * weight);
                    let water = Math.round(Number.parseFloat(element.water) * weight);

                    var tmp1 = { x: String(d), y: weight };
                    var tmp2 = { x: String(d), y: fat };
                    var tmp3 = { x: String(d), y: muscle };
                    var tmp4 = { x: String(d), y: water };

                    // index++;
                    series1.push(tmp1);
                    series2.push(tmp2);
                    series3.push(tmp3);
                    series4.push(tmp4);
                }
            }

        }

        var series = [{
            name: 'poids',
            type: 'line',
            data: series1,

        },
        {
            name: 'graisse',
            type: 'line',
            data: series2,

        },

        {
            name: 'muscle',
            type: 'line',
            data: series3,

        },

        {
            name: 'eau',
            type: 'line',
            data: series4,

        }
        ]
        return series;
    },
    stateForMoodLineChartTrainer: (state) => {
        var fat = [];
        var sle = [];
        var str = [];
        var mot = [];
        var moo = [];
        var rpe = [];
        var datas = JSON.parse(JSON.stringify(state.local_stateOfFOrm));
        if (datas != undefined) {
            for (const element of datas) {
                if (element != undefined && element != "empty" && element.isDaily === 1) {
                    let d = new Date(element.evalDate).toLocaleDateString('fr');
                    fat.push({ x: String(d), y: element.fatigue });
                    sle.push({ x: String(d), y: element.sleep });
                    str.push({ x: String(d), y: element.stress });
                    mot.push({ x: String(d), y: element.motivation });
                    moo.push({ x: String(d), y: element.mood });
                    rpe.push({ x: String(d), y: element.RPE_WEEK });

                }
            }
        }

        var series = [{
            name: 'fatigue',
            type: 'line',
            data: fat,

        },
        {
            name: 'sommeil',
            type: 'line',
            data: sle,

        },

        {
            name: 'stress',
            type: 'line',
            data: str,

        },

        {
            name: 'motivation',
            type: 'line',
            data: mot,

        },
        {
            name: 'Emotions',
            type: 'line',
            data: moo,

        },
        {
            name: 'difficulte',
            type: 'line',
            data: rpe,

        },
        ];
        return series;
    },



};

const actions = {
    async getCompoForTrainer({ commit }) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            //
            var result = await clt.readTraineeForTrainer(token);
            await commit('setListComposition', result.result.compositions);
            await commit('setListTrainer', result.result.trainers);
        }
    },
    async getTeamForTrainer({ commit }) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            //
            if (this.getters.trainer_compo == undefined || this.getters.trainer_compo.length == 0) {
                let result = await clt.readTraineeForTrainer(token);
                await commit('setListComposition', result.result.compositions);
            }
            if(this.getters.trainer_compo.length > 0){
                var list_ids = this.getters.trainer_compo.map(x => x.composition_idTeam);
                var result = await clt.readTeamsByIds(token, list_ids);
                await commit('setListTeams', result.result);

            }
            
        }
    },
    async getAthleteForTrainer({ commit }) {
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            if (this.getters.trainer_compo == undefined || this.getters.trainer_compo.length == 0) {
                let result = await clt.readTraineeForTrainer(token);
                await commit('setListComposition', result.result.compositions);
            }
            if(this.getters.trainer_compo.length){
                var list_ids = this.getters.trainer_compo.map(x => x.composition_idAthlete);
                var result = await clt.readAthletesByIds(token, list_ids);
                await commit('setListAthlete', result.result);
            }
            
        }
    },
    async getStateOfFormTrainerAthl({ commit }) {
        
        var token = this.getters.tokenUser;
        if (token == null) {
            token = localStorage.getItem("token");
        }
        if (token) {
            var athlete = this.getters.trainer_athlete.find(elem => elem.idAthlete == this.getters.trainer_selected_athlete.idAthlete);
            var result = await clt.readStateFormAthletes(token, athlete);
            await commit("setCurrentStatesForm", result.result);

        }
    },

    async trainer_setAthleteById({ commit }, id) {
        var athlete = this.getters.trainer_getAthleteById(id);
        await commit("setCurrentAthlete", athlete);
    },
    async reset_localStates({ commit }) {
        await commit("setCurrentAthlete", false);
        await commit("setCurrentStatesForm", false);
    },
    async cleanStatesEntraineur({commit}){
      await commit("cleanStatesEntraineur");  
    },
    // async getStatesForMass(id){
    //     var token = this.getters.tokenUser;
    //     if (token == null) {
    //         token = localStorage.getItem("token");
    //     }
    //     if (token) { 
    //         let athlete=this.getters.trainer_athleteById(id);
    //         var result=await clt.read(token,athlete);
    //         return result.result;

    //     }
    // }
};

const mutations = {
    setCurrentAthlete(state, athlete) {
        state.current_athlete = athlete;
    },
    setListComposition(state, lcompos) {
        state.trainer_athl_compo = lcompos;

    },
    setListTrainer(state, ltrainer) {
        state.local_trainer = ltrainer;
    },
    setListTeams(state, lteam) {
        state.trainer_teams = lteam;
    },
    setListAthlete(state, lathlete) {
        state.trainer_athlete = lathlete;
    },
    setCurrentStatesForm(state, lStateForms) {
        state.local_stateOfFOrm = lStateForms;
    },
    cleanStatesEntraineur(state){
        state.local_trainer= [];
        state.trainer_teams= [];
        state.trainer_athlete= [];
        state.trainer_athl_compo= [];
        state.current_athlete= null;
        state.local_stateOfFOrm= [];
    }

};
export default {
    state,
    getters,
    actions,
    mutations
};