import { createStore } from "vuex";

// import notes from './modules/notes';
import users from './modules/users';
import athletes from './modules/athletes';
import suivis from './modules/suivis';
import compositions from "./modules/compositions";
import entraineurs from "./modules/entraineurs";
export default createStore({
  modules: {
    athletes,
    users,
    suivis,
    compositions,
    entraineurs,
  }
});