<template>
    <div >

  <header>
    <h1>Bienvenu sur le site de gestion d'équipe du  CEP</h1>
  </header>
    <div>
        Vous avez demander à vous enregistrer avec le mail {{  query_params.mail }}
    </div>
    <div>
         {{ messages[query_params.message]  }}
    </div>
    <footer>
            <span><a href="mailto:{{query_params.sender_email}}">contact</a></span>
    </footer>
 
    </div>
  </template>
<script>
import { defineComponent} from 'vue';
import '@/css/forms.css';
export default defineComponent({
    name: "Confirm-view",
    components: {
        
    },
    data() {
        return{
            messages:[
            "Nous ne trouvons pas de demande d'enregistrement de votre part dans le délai des 48 dernières heures, veuillez recommencer.",
            "Votre email existe déjà dans notre base de données comme email activé",
            "Vous avez déjà confirmé votre inscription contacter le support du site SVP si vous ne pouvez pas vous connecter",
            "Votre inscription a été prise en cours, vous pouvez vous connecter via le site ",
            "Une erreur nous empêche de valider votre inscription, SVP refaite la demande via le site", 
            ],
            query_params:this.$route.query,}
    }
});
</script>